import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { Modal } from "../Modal";
import styles from "./styles.module.css";
import { isArabic } from "../../utils/helpers";

const MyNotificationsList = ({ myNotificationsList }) => {
  const [modalVisible, setModalVisible] = useState(false);

  var collecSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const titleClassName = isArabic(myNotificationsList.notification_title)
    ? styles["arabicTitle"]
    : styles["englishTitle"];

  const descriptionClassName = isArabic(
    myNotificationsList.notification_description,
  )
    ? styles["arabicDescription"]
    : styles["englishDescription"];

  return (
    <>
      <div className="bookingbox" onClick={() => setModalVisible(true)}>
        <div className="bookingboxlft">
          <p>
            {" "}
            Date{" "}
            <span>
              {moment(new Date(myNotificationsList.expiry_timestamp)).format(
                "ll",
              )}
            </span>
          </p>
          <div className={styles.notificationContent}>
            {myNotificationsList?.image ? (
              <div className={styles.notificationImage}>
                <img src={myNotificationsList?.image} alt="placeholder" />
              </div>
            ) : null}
            <div>
              <h3 className={titleClassName}>
                {myNotificationsList.notification_title
                  ? myNotificationsList.notification_title
                  : "New Points Added"}
              </h3>
              <p className={descriptionClassName}>
                {myNotificationsList.notification_title
                  ? myNotificationsList.notification_description
                  : "Points added to your account. Redeem for best deal."}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalVisible={modalVisible}
        handleClose={() => setModalVisible(false)}
      >
        <h3 className={titleClassName}>
          {myNotificationsList.notification_title}
        </h3>
        {myNotificationsList?.image ? (
          <img
            src={myNotificationsList?.image}
            alt="placeholder"
            style={{
              width: "300px",
              marginInline: "auto",
              objectFit: "contain",
            }}
          />
        ) : null}
        <p className={descriptionClassName}>
          {myNotificationsList.notification_description}
        </p>
        <p className="text-muted mt-auto" style={{ opacity: 0.5 }}>
          {moment(new Date(myNotificationsList.expiry_timestamp)).format("ll")}
        </p>
      </Modal>
    </>
  );
};

export default MyNotificationsList;
