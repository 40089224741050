import React from "react";
import "./MapMarkerHoveredCard.css";
import { useTranslation } from "react-i18next";
import { selectlanguageToShow } from "../../redux/language/language.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { PromoCodeComponent } from "../PormoCodeLabel";

const MapMarkerHoveredCard = ({ place, languageToShow, onClick }) => {
  const [t] = useTranslation("common");

  return (
    <div className="map-marker-hovered-card" onClick={onClick}>
      <div className="map-marker-hovered-card-image-container">
        <PromoCodeComponent isVisible={place.coupon.length > 0} />
        <img
          src={place.images[0]}
          alt={place.name}
          className="map-marker-hovered-card-image"
        />
      </div>
      <div className="map-marker-hovered-card-content">
        <h3 className="map-marker-hovered-card-title-stars-container">
          <span className="map-marker-hovered-card-title-with-stars">
            {place?.name || "Unknown Place"}
            <span className="map-marker-hovered-card-stars">
              {[...Array(place?.star || 0)].map((_, i) => (
                <img
                  src="./img/icon-review-star.svg"
                  key={`star-${i}`}
                  alt="star"
                />
              ))}
            </span>
          </span>
        </h3>

        <div className="map-marker-hovered-card-badges-container">
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {place.recomended === true && (
              <div className="recommendedTagBox">
                <p className="recommendedTag">
                  <span>{t("hotelList.sortBy.Recommended")}</span>
                </p>
              </div>
            )}

            {place?.lastRoom == 1 ? (
              <div className="recommendedTagBox">
                <p className="lastAvaliable">
                  <span>
                    {t(
                      "hotelDetails.hotelDetail.RoomDetails.LastRoomAvailable"
                    )}
                  </span>
                </p>
              </div>
            ) : (
              ""
            )}

            {place?.lastRoom == 2 ? (
              <div className="lastAvaliable">
                <span>
                  {t(
                    "hotelDetails.hotelDetail.RoomDetails.Last2RoomsAvailable"
                  )}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="map-marker-hovered-card-price-container">
          <div className="map-marker-hovered-card-rating-container">
            <div className="map-marker-hovered-card-rating-box">
              <p
                className={`map-marker-hovered-card-feedback${
                  languageToShow === "ar" ? " feedback-ar" : ""
                }`}
              >
                {place?.review?.feedback_type}
              </p>
              <p
                className={`map-marker-hovered-card-reviews${
                  languageToShow === "ar" ? " reviews-ar" : ""
                }`}
              >
                {place?.review?.guests} {t("hotelList.reviews")}
              </p>
            </div>
            <div className="map-marker-hovered-card-rating-text">
              {" "}
              {place?.review?.rating === "10.0" ? "10" : place?.review?.rating}
            </div>
          </div>
          <div className="price">
            {t("StartingAt")} {place.min_price} {place.currency}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
});

export default connect(mapStateToProps)(MapMarkerHoveredCard);
