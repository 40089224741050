import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

export const PromoCodeComponent = ({ isVisible }) => {
  const [t] = useTranslation("common");

  if (isVisible)
    return (
      <div className={styles.labelContainer}>
        <span className={styles.labelTail} />
        <p className={styles.labelText}>
          {t("hotelDetails.hotelDetail.RoomDetails.promoCode")}
        </p>
      </div>
    );
};
