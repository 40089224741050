import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation } from "react-router-dom";

import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import i18next from "i18next";
import {
  Form as BootstrapForm,
  Button,
  Col,
  Container,
  Row,
  Spinner,
} from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { connect, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import store from "../../../../redux/store";
import {
  ProfileDataUpdateFailure,
  ProfileDataUpdateRequest,
  requestSocialLogin,
  UserProfileDetailsUpdateThroughProfile,
} from "../../../../redux/user/user.actions";
import { selectCurrentUser } from "../../../../redux/user/user.selectors";
import { useTranslation } from "react-i18next";

var MySwal = withReactContent(Swal);

const ReconfirmUserData = ({
  selectCurrentUser,
  updateUser,
  requestSocialLogin,
}) => {
  const [t] = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, t("loginAndRegisterPage.validationMsg.Minimum3Characters"))
      .required(
        t("loginAndRegisterPage.validationMsg.PleaseEnterYourFirstName")
      )
      .matches(/^[a-zA-Z\s\-]*$/, "English only, Please"),
    lastname: Yup.string()
      .min(3, t("loginAndRegisterPage.validationMsg.Minimum3Characters"))
      .required(t("loginAndRegisterPage.validationMsg.PleaseEnterYourLastName"))
      .matches(/^[a-zA-Z\s\-]*$/, "English only, Please"),
    phoneno: Yup.string()
      .required(t("loginAndRegisterPage.validationMsg.PleaseEnteronlyNumber"))
      .min(10, t("loginAndRegisterPage.validationMsg.digitnumberallowonly10")),
    email: Yup.string()
      .email(
        t("loginAndRegisterPage.validationMsg.PleaseEnteraValidEmailAddress")
      )
      .required(
        t("loginAndRegisterPage.validationMsg.PleaseEnteraValidEmailAddress")
      ),
    termsandConditions: Yup.boolean().oneOf(
      [true],
      t("loginAndRegisterPage.validationMsg.PleaseAcceptTermsAndConditions")
    ),
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (state?.new_user) {
        const postData = {
          first_name: values.firstname,
          last_name: values.lastname,
          country_code: values.country_code,
          contact_no: values.phoneno,
          email: values.email,
          social_platform: "google",
          social_login_id: state?.id,
          social_login_token: state?.users.access_token,
        };
        return await requestSocialLogin(postData);
      }

      const updateResponse = await axios.put(
        process.env.REACT_APP_BACKEND_API_ENDPOINT + "/user/update",
        {
          first_name: values.firstname,
          last_name: values.lastname,
          country_code: values.country_code,
          contact_no: values.phoneno,
          email: values.email,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mobile: true,
            "Accept-Language": `${store.getState().language.language}`,
            Authorization:
              store.getState().user.currentUser != null
                ? "Bearer " + store.getState().user.currentUser.token + ""
                : "",
          },
        }
      );
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_API_ENDPOINT}/user/get`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            mobile: true,
            "Accept-Language": `${store.getState().language.language}`,
            Authorization:
              store.getState().user.currentUser != null
                ? "Bearer " + store.getState().user.currentUser.token + ""
                : "",
          },
        }
      );
      dispatch(updateUser(data.data)); // Update user data
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#5287b3",
        text: updateResponse.data.message,
      }).then(function (isConfirm) {
        if (isConfirm) {
          window.location.href = "/";
        }
      });
    } catch (error) {
      dispatch(ProfileDataUpdateFailure(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneNumberOnKeyUp = (e) => {
    // Allow only numbers
    // don't allow alphabets this logic is replacing after entering the strings
    // e.target.value = e.target.value.replace(/[^0-9]/g, "");

    const numbersRegex = /^[0-9]*$/;

    if (!numbersRegex.test(e.target.value)) {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="logtotal customform reconfirm_user_data_container">
            <h3 style={{ marginBottom: "1rem" }}>
              {t("loginAndRegisterPage.reconfirmUserData")}
            </h3>
            <Formik
              initialValues={{
                firstname: state?.given_name || state.first_name,
                lastname: state?.family_name || state.last_name,
                phoneno: state?.contact_no || "",
                email: state?.email,
                termsandConditions: false,
                country_code: state?.country_code || "+966",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnMount
            >
              {({
                handleSubmit,
                isSubmitting,
                isValid,
                setFieldValue,
                values,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="form50">
                    <BootstrapForm.Group controlId="formBasicFirstname">
                      <BootstrapForm.Label>
                        {t("loginAndRegisterPage.FirstName")} *
                      </BootstrapForm.Label>
                      <Field
                        name="firstname"
                        type="text"
                        placeholder={t("loginAndRegisterPage.FirstName")}
                        as={BootstrapForm.Control}
                      />
                      <ErrorMessage
                        name="firstname"
                        component={(props) => <BootstrapForm.Text {...props} />}
                        className="text-muted errorformmessage"
                      />
                    </BootstrapForm.Group>
                    <BootstrapForm.Group controlId="formBasicLastname">
                      <BootstrapForm.Label>
                        {t("loginAndRegisterPage.LastName")} *
                      </BootstrapForm.Label>
                      <Field
                        name="lastname"
                        type="text"
                        placeholder={t("loginAndRegisterPage.FirstName")}
                        as={BootstrapForm.Control}
                      />
                      <ErrorMessage
                        name="lastname"
                        component={(props) => <BootstrapForm.Text {...props} />}
                        className="text-muted errorformmessage"
                      />
                    </BootstrapForm.Group>
                  </div>

                  <div className="form50 countryCodePhoneDiv">
                    <BootstrapForm.Group
                      controlId="formBasicPhone"
                      style={{
                        width: "max-content",
                      }}
                    >
                      <BootstrapForm.Label>
                        {t("loginAndRegisterPage.CountryCode")} *
                      </BootstrapForm.Label>
                      <Field
                        name="country_code"
                        type="text"
                        as={PhoneInput}
                        inputProps={{
                          name: "country_code",
                          readOnly: true,
                        }}
                        placeholder={"+91"}
                        enableSearch={true}
                        excludeCountries={[
                          "cu",
                          "ru",
                          "kp",
                          "ua",
                          "ir",
                          "sd",
                          "ss",
                          "sy",
                        ]}
                        className="countryCodeInput reconfirm_country_code"
                      />

                      <ErrorMessage
                        name="country_code"
                        component="div"
                        className="text-muted errorformmessage"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="formBasicPhone">
                      <BootstrapForm.Label>
                        {t("loginAndRegisterPage.phoneNumberText")} *
                      </BootstrapForm.Label>
                      <Field
                        name="phoneno"
                        type="tel"
                        placeholder={t("loginAndRegisterPage.PhoneNumber")}
                        as={BootstrapForm.Control}
                        maxLength={10}
                        minLength={10}
                        onKeyDown={(e) => {
                          // Allow only numbers, backspace, delete, and arrow keys
                          const allowedKeys = [
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                            "Tab",
                            "Home",
                            "End",
                          ];
                          if (!allowedKeys.includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <ErrorMessage
                        name="phoneno"
                        component="div"
                        className="text-muted errorformmessage"
                      />
                    </BootstrapForm.Group>
                  </div>

                  <BootstrapForm.Group controlId="formBasicEmailForRegister">
                    <BootstrapForm.Label>
                      {t("loginAndRegisterPage.Email")} *
                    </BootstrapForm.Label>
                    <Field
                      name="email"
                      type="email"
                      placeholder={t("loginAndRegisterPage.Emailaddress")}
                      as={BootstrapForm.Control}
                    />
                    <ErrorMessage
                      name="email"
                      component={(props) => <BootstrapForm.Text {...props} />}
                      className="text-muted errorformmessage"
                    />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group
                    className="form-checkbox"
                    controlId="formBasicCheckbox"
                    style={{
                      paddingTop: "13px",
                    }}
                  >
                    <Field
                      name="termsandConditions"
                      type="checkbox"
                      as={BootstrapForm.Check}
                      label=""
                    />
                    <div
                      className="TC-message-container"
                      onClick={() => {
                        setFieldValue(
                          "termsandConditions",
                          !values.termsandConditions
                        );
                      }}
                    >
                      {t("loginAndRegisterPage.Iagreeallthe")}{" "}
                      <Link to="/terms&conditions" target="_blank">
                        {t("loginAndRegisterPage.termsandconditions")}
                      </Link>{" "}
                      <span className="px-1">
                        {" "}
                        {t("loginAndRegisterPage.&")}
                      </span>{" "}
                      <Link to="/privacy-policy" target="_blank">
                        {t("loginAndRegisterPage.privacypolicy")}
                      </Link>
                    </div>
                    <ErrorMessage
                      name="termsandConditions"
                      component={(props) => <BootstrapForm.Text {...props} />}
                      className="text-muted errorformmessage"
                    />
                  </BootstrapForm.Group>

                  <div className="d-flex gap-3 w-100">
                    <Button
                      variant="primary"
                      type="submit"
                      className="formsubmit outline-primary"
                      disabled={isLoading || isSubmitting || !isValid}
                    >
                      {isLoading ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                    <Button
                      variant="primary"
                      as={Link}
                      className="formsubmit outline-primary"
                      disabled={isLoading || isSubmitting}
                      to="/"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  selectCurrentUser: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  ProfileDataUpdateRequest: (data) => dispatch(ProfileDataUpdateRequest(data)),
  updateUser: (data) => dispatch(UserProfileDetailsUpdateThroughProfile(data)),
  requestSocialLogin: (data) => dispatch(requestSocialLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReconfirmUserData);
