import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

const CheckoutHotelDetails = ({ CheckoutHotelDetailsData }) => {
  const [t] = useTranslation("common");
  const [aminityDetailsShow, setAminityDetailsShow] = useState(true);
  const handleShow = () => {
    setAminityDetailsShow(!aminityDetailsShow);
  };

  var collecSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="WL_right_rivewbox d-block listing-box hotel-details-card custom-hotel-details-card">
      <div className="WL_collection_left d-flex justify-content-between w-auto p-0">
        {/* ///left section start/// */}
        <div className="d-flex gap-2 align-items-center">
          <h4 className="WL_right_rivewbox_heading">
            {CheckoutHotelDetailsData?.data?.hotel_details?.name}
          </h4>
        </div>

        {/* ///left section end/// */}

        {/* ///right section start/// */}
        <div className="WL_collection_right">
          <div className="reviewstar mt-0">
            {CheckoutHotelDetailsData?.data?.hotel_details?.star != null ? (
              [
                ...Array(CheckoutHotelDetailsData?.data?.hotel_details?.star),
              ].map((e, i) => <img src="./img/star.svg" alt="" key={i} />)
            ) : (
              <>
                <img src="./img/star.svg" alt="" />
                <img src="./img/star.svg" alt="" />
                <img src="./img/star.svg" alt="" />
                <img src="./img/star.svg" alt="" />
                <img src="./img/star.svg" alt="" />
              </>
            )}
          </div>
        </div>
        {/* ///right section end/// */}
      </div>
      <div className="WL_review_mwrp ">
        {/* ///slider start/// */}
        <div className="WL_coll_slider WL_coll_hotel_checkout_slider">
          <Slider {...collecSlider}>
            {CheckoutHotelDetailsData?.data?.hotel_details?.images.map(
              (image, index) => (
                <img
                  src={image}
                  alt=""
                  className="WL_coll_slider_img"
                  key={index}
                />
              )
            )}
          </Slider>
        </div>
        {/* ///slider end/// */}
        {/* ///review text container Start/// */}
        <div className="WL_inner_review_cont d-block hotel-details-card-right">
          {/* ///review text Start/// */}
          <div className="customize-sec">
            <div className="WL_reviewtext_cont justify-content-start">
              {/* <div className="address d-flex gap-1"> */}
              <div className="icon">
                <img src="./img/icon-address.svg" alt="" />
              </div>
              <p className="px-1 address">
                <span>
                  {
                    CheckoutHotelDetailsData?.data?.hotel_details?.address
                      .address_line1
                  }
                </span>
                ,{" "}
                {CheckoutHotelDetailsData?.data?.hotel_details?.address
                  .city_village && (
                  <span>
                    {
                      CheckoutHotelDetailsData?.data?.hotel_details?.address
                        .city_village
                    }{" "}
                  </span>
                )}{" "}
                {CheckoutHotelDetailsData?.data?.hotel_details?.address
                  ?.pincode && (
                  <span>
                    -
                    {
                      CheckoutHotelDetailsData?.data?.hotel_details?.address
                        .pincode
                    }
                    ,
                  </span>
                )}{" "}
                {CheckoutHotelDetailsData?.data?.hotel_details?.address
                  ?.district && (
                  <span>
                    {
                      CheckoutHotelDetailsData?.data?.hotel_details?.address
                        .district
                    }
                    ,
                  </span>
                )}{" "}
                {CheckoutHotelDetailsData?.data?.hotel_details?.address
                  ?.state && (
                  <span>
                    {
                      CheckoutHotelDetailsData?.data?.hotel_details?.address
                        .state
                    }
                    ,
                  </span>
                )}{" "}
                {CheckoutHotelDetailsData?.data?.hotel_details?.address
                  .post_office && (
                  <span>
                    {
                      CheckoutHotelDetailsData?.data?.hotel_details?.address
                        .post_office
                    }
                    ,
                  </span>
                )}{" "}
                {CheckoutHotelDetailsData?.data?.hotel_details?.address.country}
              </p>
            </div>
            <div className="WL_collection_right">
              <div className="WL_revw_left">
                <p className="WL_revw_text1">
                  {
                    CheckoutHotelDetailsData?.data?.hotel_details?.review
                      ?.feedback_type
                  }
                </p>
                <p className="WL_revw_text2">
                  {
                    CheckoutHotelDetailsData?.data?.hotel_details?.review
                      ?.guests
                  }{" "}
                  {t("checkoutPage.checkoutHotelDetails.GuestReviews")}
                </p>
              </div>
              <div className="WL_revw_right">
                {Number(
                  CheckoutHotelDetailsData?.data?.hotel_details?.review?.rating
                ).toFixed(1) === "10.0"
                  ? 10
                  : Number(
                      CheckoutHotelDetailsData?.data?.hotel_details?.review
                        ?.rating
                    ).toFixed(1)}
              </div>
            </div>
          </div>
          {/* ///review text end/// */}

          <div className="amenities">
            <div className="amenities-item">
              <ul>
                {aminityDetailsShow
                  ? CheckoutHotelDetailsData?.data?.hotel_details?.amenities
                      ?.length > 0
                    ? CheckoutHotelDetailsData?.data?.hotel_details?.amenities
                        .slice(0, 6)
                        .map((item, i) => {
                          return (
                            <li key={i}>
                              <span className="icon">
                                <img src={item.amenity_icon} alt="" />
                              </span>
                              {item.amenity_name}
                            </li>
                          );
                        })
                    : null
                  : CheckoutHotelDetailsData?.data?.hotel_details?.amenities
                      ?.length > 0
                  ? CheckoutHotelDetailsData?.data?.hotel_details?.amenities.map(
                      (item, i) => {
                        return (
                          <li key={i}>
                            <span className="icon">
                              <img src={item.amenity_icon} alt="" />
                            </span>
                            {item.amenity_name}
                          </li>
                        );
                      }
                    )
                  : null}
              </ul>
              {CheckoutHotelDetailsData?.data?.hotel_details?.amenities
                ?.length > 6 ? (
                <p
                  className="link-more"
                  onClick={handleShow}
                  style={{ cursor: "pointer" }}
                >
                  {aminityDetailsShow
                    ? t("checkoutPage.checkoutHotelDetails.seemoreamenities")
                    : t("checkoutPage.checkoutHotelDetails.less")}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {/* ///review text container end/// */}
      </div>
    </div>
  );
};

export default CheckoutHotelDetails;
