import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import validator from "validator";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { selectcurrencyToShow } from "./../../redux/currency/currency.selectors";
import {
  selectUserLoginData,
  selectValidateUserEmailData,
  selectSignUpMessage,
  selectCurrentUser,
} from "./../../redux/user/user.selectors";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { errorToast } from "../../utils/toastHelper";
import { Link } from "react-router-dom";
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import {
  selectBidCheckoutDetails,
  selectHourlyCheckoutDetails,
  selectHotelBooking,
  selectApplyHotelOffers,
  selectSaveGuestUserCheckoutData,
  selectFavouriteHotelSearchData,
  selectApplyHotelOffersFailedData,
  selectHotelBookingLoading,
  selectApplyHotelOffersLoading,
} from "../../redux/hotels/hotel.selectors";
import {
  getBidCheckoutDetailsRequest,
  getHourlyCheckoutDetailsRequest,
  hotelBookingRequest,
  applyHotelOffersRequest,
  stateClearAfterTask,
  saveGuestUserCheckoutDataRequest,
  hotelWheatherApiRequest,
} from "../../redux/hotels/hotel.actions";
import {
  validateUserEmailRequest,
  validateEmailStateClearAfterTask,
} from "../../redux/user/user.actions";
import moment from "moment";
import {
  useSearchParams,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import CheckoutUserForm from "../../components/checkout/CheckoutUserForm";
import CheckoutCardDetailsForm from "../../components/checkout/CheckoutCardDetailsForm";
import CheckoutHotelDetails from "../../components/checkout/CheckoutHotelDetails";
import {
  CardNumberFormatValidator,
  CardExpiryFormatValidator,
  MobileNumberValidator,
  EmailValidator,
} from "../../constants/InputValidator";
import ConfirmDialog from "../../components/common/dialogs/ConfirmDialog";
import BookingDetailsDialog from "../../components/checkout/BookingDetailsDialog";
import InfoModal from "../../components/checkout/InfoModal";
import LoginModal from "../../components/checkout/LoginModal";
import BookingConditionModal from "../../components/checkout/BookingCondition";
import LoginPage from "../../pages/Login/Login";
import BookingConfirmationDetailsModal from "../../components/checkout/BookingConfirmationDetailsModal";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import ViewPromocodeList from "../../components/checkout/viewPromocodeList";
import UnionPay from "./../../assets/images/union.png";
import applepay from "./../../assets/images/pay.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SamsungPayIcon from "../../assets/icons/SamsungPayIcon";

const BidGuestUser = ({
  bidCheckoutDetails,
  getBidCheckoutDetailsRequest,
  hotelBookingRequest,
  hotelBooking,
  applyHotelOffersRequest,
  applyHotelOffers,
  languageToShow,
  userAuthData,
  stateClearAfterTask,
  saveGuestUserCheckoutDataRequest,
  saveGuestUserCheckoutData,
  currencyToShow,
  searchsavedData,
  applyHotelOffersFailedData,
  hotelWheatherApiRequest,
  validateUserEmailRequest,
  validateUserEmail,
  validateEmailStateClearAfterTask,
  hotelBookingLoading,
  applyHotelOffersLoading,
  signUpmessage,
  currentUser,
}) => {
  const [t] = useTranslation("common");
  const location = useLocation();
  let {
    search_type,
    hotel_id,
    room_type_id,
    check_in_date,
    check_out_date,
    adults,
    rooms,
    children,
    slot_id,
    book_for,
    city,
  } = location.state;
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  var [show, setShow] = useState(false);
  var handleClose = () => setShow(false);
  var handleShow = () => setShow(true);
  const [bookingConditionShow, setBookingConditionShow] = useState(false);
  var [showLoginPopup, setShowLoginPopup] = useState(false);

  const [paymentModeValue, setPaymentModeValue] = React.useState("card");
  const [walletBal, setWalletBal] = React.useState("");
  const [walletBalErr, setWalletBalErr] = React.useState("");
  const [promoCode, setPromoCode] = React.useState("");
  const [promoCodeErr, setPromoCodeErr] = React.useState("");

  const [biddingAmount, setBiddingAmount] = React.useState("");
  const [biddingAmountErr, setBiddingAmountErr] = React.useState("");
  const [showCardTag, setShowCardTag] = React.useState(false);
  const [disabledBidding, setDisabledBidding] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [disabledBiddingInput, setDisabledBiddingInput] = React.useState(false);
  const [applyHotelOfferState, setApplyHotelOfferState] = React.useState(
    applyHotelOffers?.code != 3014 ? applyHotelOffers : null
  );
  const [loginPopupDialog, setLoginPopupDialog] = React.useState(false);
  const [videPromoList, setViewPromoList] = React.useState(false);

  const [userData, setUserData] = React.useState({
    firstname:
      bidCheckoutDetails?.data?.user_details?.first_name != null
        ? bidCheckoutDetails?.data?.user_details?.first_name
        : "",
    lastname:
      bidCheckoutDetails?.data?.user_details?.last_name != null
        ? bidCheckoutDetails?.data?.user_details?.last_name
        : "",
    email:
      bidCheckoutDetails?.data?.user_details?.email != null
        ? bidCheckoutDetails?.data?.user_details?.email
        : "",
    confirmEmail:
      bidCheckoutDetails?.data?.user_details?.email != null
        ? bidCheckoutDetails?.data?.user_details?.email
        : "",
    mobileNo:
      bidCheckoutDetails?.data?.user_details?.contact_n != null
        ? bidCheckoutDetails?.data?.user_details?.contact_no
        : "",

    expecedTimeArrival:
      saveGuestUserCheckoutData?.user_details?.expecedTimeArrival != null
        ? saveGuestUserCheckoutData?.user_details?.expecedTimeArrival
        : "",
  });

  const [userDataError, setuserDataError] = React.useState({
    firstnameErr: "",
    lastnameErr: "",
    emailErr: "",
    confirmEmailErr: "",
    mobileNoErr: "",
  });

  const [cardData, setCardData] = React.useState({
    cardnumber:
      saveGuestUserCheckoutData?.card_details?.cardnumber != null
        ? saveGuestUserCheckoutData?.card_details?.cardnumber
        : "",
    nameoncard:
      saveGuestUserCheckoutData?.card_details?.nameoncard != null
        ? saveGuestUserCheckoutData?.card_details?.nameoncard
        : "",
    expirydate:
      saveGuestUserCheckoutData?.card_details?.expirydate != null
        ? saveGuestUserCheckoutData?.card_details?.expirydate
        : "",
    securitycode:
      saveGuestUserCheckoutData?.card_details?.securitycode != null
        ? saveGuestUserCheckoutData?.card_details?.securitycode
        : "",
    selectedCardType:
      saveGuestUserCheckoutData?.card_details?.selectedCardType != null
        ? saveGuestUserCheckoutData?.card_details?.selectedCardType
        : "",
    zipcode:
      saveGuestUserCheckoutData?.card_details?.zipcode != null
        ? saveGuestUserCheckoutData?.card_details?.zipcode
        : "",
    termsandConditions:
      saveGuestUserCheckoutData?.card_details?.termsandConditions != null
        ? saveGuestUserCheckoutData?.card_details?.termsandConditions
        : false,
  });

  const [cardDataError, setCardDataError] = React.useState({
    cardnumberErr: "",
    nameoncardErr: "",
    expirydateErr: "",
    securitycodeErr: "",
    selectedCardTypeErr: "",
    zipcodeErr: "",
    termsandConditionsErr: "",
  });

  const [countryCode, setCountryCode] = React.useState(
    bidCheckoutDetails?.data?.user_details?.country_code != null
      ? bidCheckoutDetails?.data?.user_details?.country_code
      : "+966"
  );
  const [countryCodeErr, setCountryCodeErr] = React.useState("");

  const translatedMessageObj = {
    MobileNumberValidatorMsgObj: {
      pleaseEnterYourMobileNumber: t(
        "validatorMsg.pleaseEnterYourMobileNumber"
      ),
      PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
      Atleast10DigitsRequired: t("validatorMsg.Atleast10DigitsRequired"),
      MobileNumberIsInvalid: t("validatorMsg.MobileNumberIsInvalid"),
    },
    EmailValidatorMsgObj: {
      PleaseEnterYourEmail: t("validatorMsg.PleaseEnterYourEmail"),
      PleaseEnteraValidEmailAddress: t(
        "validatorMsg.PleaseEnteraValidEmailAddress"
      ),
    },
    PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
  };

  const firstnameRef = React.useRef();
  const lastNameRef = React.useRef();
  const emailRef = React.useRef();
  const confirmEmailRef = React.useRef();
  const mobileNoRef = React.useRef();
  const countryCodeRef = React.useRef();
  const expectedTimeArrRef = React.useRef();
  const cardnumberRef = React.useRef();
  const nameoncardRef = React.useRef();
  const expirydateRef = React.useRef();
  const securitycodeRef = React.useRef();
  const selectedCardTypeRef = React.useRef();
  const zipcodeRef = React.useRef();
  const biddingAmountRef = React.useRef();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showBookingDetailsModal, setShowBookingDetailsModal] = useState(false);
  const [showBookingConfirmModal, setShowBookingConfirmModal] = useState(false);
  const [pointsApplied, setPointsApplied] = React.useState(false);
  const [promoCodeApplied, setPromoCodeApplied] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  /*** Set Default User Details  ***/
  React.useEffect(() => {
    setUserData(
      bidCheckoutDetails != null
        ? {
            firstname:
              bidCheckoutDetails?.data?.user_details?.first_name != null
                ? bidCheckoutDetails?.data?.user_details?.first_name
                : "",
            lastname:
              bidCheckoutDetails?.data?.user_details?.last_name != null
                ? bidCheckoutDetails?.data?.user_details?.last_name
                : "",
            email:
              bidCheckoutDetails?.data?.user_details?.email != null
                ? bidCheckoutDetails?.data?.user_details?.email
                : "",
            confirmEmail:
              bidCheckoutDetails?.data?.user_details?.email != null
                ? bidCheckoutDetails?.data?.user_details?.email
                : "",
            mobileNo:
              bidCheckoutDetails?.data?.user_details?.contact_no != null
                ? bidCheckoutDetails?.data?.user_details?.contact_no
                : "",
            expecedTimeArrival:
              saveGuestUserCheckoutData?.user_details?.expecedTimeArrival !=
              null
                ? saveGuestUserCheckoutData?.user_details?.expecedTimeArrival
                : "",
          }
        : {
            firstname: "",
            lastname: "",
            email: "",
            confirmEmail: "",
            mobileNo: "",
            expecedTimeArrival:
              saveGuestUserCheckoutData?.user_details?.expecedTimeArrival != ""
                ? saveGuestUserCheckoutData?.user_details?.expecedTimeArrival
                : "",
          }
    );
    setCountryCode(
      bidCheckoutDetails != null
        ? bidCheckoutDetails?.data?.user_details?.country_code != null
          ? bidCheckoutDetails?.data?.user_details?.country_code
          : ""
        : ""
    );
    // cleanup function which will be invoked on component unmount
    return () => {
      setUserData({
        firstname: bidCheckoutDetails?.data?.user_details?.first_name,
        lastname: bidCheckoutDetails?.data?.user_details?.last_name,
        email: bidCheckoutDetails?.data?.user_details?.email,
        confirmEmail: bidCheckoutDetails?.data?.user_details?.email,
        mobileNo: bidCheckoutDetails?.data?.user_details?.contact_no,
        expecedTimeArrival: "",
      });
      setCountryCode(bidCheckoutDetails?.data?.user_details?.country_code);
    };
  }, [bidCheckoutDetails]);

  /***** Set default Card details for guest user login ** */
  React.useEffect(() => {
    setCardData(
      saveGuestUserCheckoutData != null &&
        saveGuestUserCheckoutData?.request_data?.search_type === "bid"
        ? {
            cardnumber:
              saveGuestUserCheckoutData?.card_details?.cardnumber != null
                ? saveGuestUserCheckoutData?.card_details?.cardnumber
                : "",
            nameoncard:
              saveGuestUserCheckoutData?.card_details?.nameoncard != null
                ? saveGuestUserCheckoutData?.card_details?.nameoncard
                : "",
            expirydate:
              saveGuestUserCheckoutData?.card_details?.expirydate != null
                ? saveGuestUserCheckoutData?.card_details?.expirydate
                : "",
            securitycode:
              saveGuestUserCheckoutData?.card_details?.securitycode != null
                ? saveGuestUserCheckoutData?.card_details?.securitycode
                : "",
            selectedCardType:
              saveGuestUserCheckoutData?.card_details?.selectedCardType != null
                ? saveGuestUserCheckoutData?.card_details?.selectedCardType
                : "",
            zipcode:
              saveGuestUserCheckoutData?.card_details?.zipcode != null
                ? saveGuestUserCheckoutData?.card_details?.zipcode
                : "",
            termsandConditions:
              saveGuestUserCheckoutData?.card_details?.termsandConditions !=
              null
                ? saveGuestUserCheckoutData?.card_details?.termsandConditions
                : false,
          }
        : {
            cardnumber: "",
            nameoncard: "",
            expirydate: "",
            securitycode: "",
            selectedCardType: "",
            zipcode: "",
            termsandConditions: false,
          }
    );

    // cleanup function which will be invoked on component unmount
    return () => {
      setCardData({
        cardnumber: saveGuestUserCheckoutData?.card_details?.cardnumber,
        nameoncard: saveGuestUserCheckoutData?.card_details?.nameoncard,
        expirydate: saveGuestUserCheckoutData?.card_details?.expirydate,
        securitycode: saveGuestUserCheckoutData?.card_details?.securitycode,
        selectedCardType:
          saveGuestUserCheckoutData?.card_details?.selectedCardType,
        zipcode: saveGuestUserCheckoutData?.card_details?.zipcode,
        termsandConditions:
          saveGuestUserCheckoutData?.card_details?.termsandCondition,
      });
    };
  }, []);

  /*** Fetching Checkout Details Bid Wise   ***/
  React.useEffect(() => {
    const postData = {
      hotel_id: hotel_id,
      room_type_id: room_type_id,
      check_in_date: check_in_date,
      check_out_date: check_out_date,
      adults: adults,
      children: children,
      no_of_rooms: rooms,
    };
    const data = {
      postData,
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
    };
    getBidCheckoutDetailsRequest(data);
    validateEmailStateClearAfterTask();
    window.scrollTo(0, 0);
  }, [languageToShow]);

  /*** Fetching Checkout Guest User details   ***/
  React.useEffect(() => {
    const data = {
      request_data: bidCheckoutDetails?.data?.request_data,
      user_details:
        bidCheckoutDetails != null
          ? Object.entries(bidCheckoutDetails?.data?.user_details).length > 0
            ? bidCheckoutDetails?.data?.user_details
            : userData
          : "",
      card_details: search_type === "bid" ? cardData : null,
      pay_type: paymentModeValue,
      promo_code: promoCode,
      bid_now_amount: biddingAmount,
      slot_id: "",
      book_for: book_for != null ? book_for : "",
      city: city != null ? city : "",
    };

    saveGuestUserCheckoutDataRequest(data);
  }, [userData, cardData, promoCode, biddingAmount, paymentModeValue]);

  React.useEffect(() => {}, [bidCheckoutDetails, validateUserEmail]);

  const handleCloseLoginPopup = () => {
    setShowLoginPopup(false);
  };

  const handleShowLoginPopup = () => setShowLoginPopup(true);

  React.useEffect(() => {
    if (userAuthData != null) {
      const postData = {
        hotel_id: hotel_id,
        room_type_id: room_type_id,
        check_in_date: check_in_date,
        check_out_date: check_out_date,
        adults: adults,
        children: children,
        no_of_rooms: rooms,
      };
      const data = {
        postData,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
      };
      getBidCheckoutDetailsRequest(data);
    }
  }, [showLoginPopup, languageToShow]);

  React.useEffect(() => {
    if (
      Object.values(userData).every((x) => x !== null || x !== "") &&
      userAuthData != null
    ) {
      setuserDataError({
        firstnameErr: "",
        emailErr: "",
        confirmEmailErr: "",
        mobileNoErr: "",
        expecedTimeArrivalErr: "",
      });
    }
  }, [userAuthData]);

  /*** Country Code Selection   ***/
  const handleCountryCodeChange = (value, data, event, formattedValue) => {
    if (data.dialCode == "") {
      setCountryCode("");
    } else {
      setCountryCode(formattedValue);
      setCountryCodeErr("");
    }
  };

  /*** Card Type Selection   ***/
  const handleChangeCardType = (e) => {
    if (e.target.name == "selectedCardType") {
      setCardData({
        ...cardData,
        selectedCardType: e.target.value,
      });
      setCardDataError({
        ...cardDataError,
        selectedCardTypeErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        selectedCardType: "",
      });
    }
  };

  /***  Cardnumber change handler  ***/
  const handleChangeCardNumber = (e) => {
    if (e.target.name == "cardnumber") {
      const cardNumberformat = CardNumberFormatValidator(e.target.value);

      setCardData({
        ...cardData,
        cardnumber: cardNumberformat,
      });
      setCardDataError({
        ...cardDataError,
        cardnumberErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        cardnumber: "",
      });
    }
  };

  const handleChangeTimePicker = (value) => {
    setUserData({
      ...userData,
      expecedTimeArrival: value,
    });
  };

  /*** Card Expiry Date handler  ***/
  const handleChangeExpiryDate = (e) => {
    if (e.target.name == "expirydate") {
      const expiryNumberFormat = CardExpiryFormatValidator(e.target.value);

      setCardData({
        ...cardData,
        expirydate: expiryNumberFormat,
      });
      setCardDataError({
        ...cardDataError,
        expirydateErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        expirydate: "",
      });
    }
  };

  /*** Personal Details Handler  ***/
  const handlePersonalDetailsChange = (e) => {
    const englishReg = /^[a-zA-Z\s\-]*$/;
    if (e.target.name == "firstname") {
      if (e.target.value === "") {
        setuserDataError({
          ...userDataError,
          firstnameErr: t(
            "checkoutPage.validationMsg.PleaseEnterYourFirstName"
          ),
        });
      } else if (!englishReg.test(e.target.value)) {
        setuserDataError({
          ...userDataError,
          firstnameErr: "English only, Please",
        });
      } else {
        setuserDataError({
          ...userDataError,
          firstnameErr: "",
        });
      }
    } else if (e.target.name === "lastname" || e.target.value.length < 3) {
      if (e.target.value === "") {
        setuserDataError({
          ...userDataError,
          lastnameErr: t("checkoutPage.validationMsg.lastnameErrMsg"),
        });
      } else if (!englishReg.test(e.target.value)) {
        setuserDataError({
          ...userDataError,
          lastnameErr: "English only, Please",
        });
      } else {
        setuserDataError({
          ...userDataError,
          lastnameErr: "",
        });
      }
    } else if (e.target.name == "email") {
      const emailError = EmailValidator(
        e.target.value,
        translatedMessageObj.EmailValidatorMsgObj
      );
      if (emailError) {
        setuserDataError({
          ...userDataError,
          emailErr: emailError,
        });
      } else {
        setuserDataError({
          ...userDataError,
          emailErr: "",
        });
      }
    } else if (e.target.name == "confirmEmail") {
      setuserDataError({
        ...userDataError,
        confirmEmailErr: "",
      });
    } else {
      if (e.target.name == "mobileNo") {
        const isNumberError = MobileNumberValidator(
          e.target.value,
          translatedMessageObj.MobileNumberValidatorMsgObj
        );
        if (isNumberError) {
          setuserDataError({
            ...userDataError,
            mobileNoErr: isNumberError,
          });
        } else {
          setuserDataError({
            ...userDataError,
            mobileNoErr: "",
          });
        }
      }
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  /*** Terms and Policy Handler  ***/
  const handletermspolicy = (e) => {
    if (e.target.checked) {
      setCardData({
        ...cardData,
        termsandConditions: true,
      });
      setCardDataError({
        ...cardDataError,
        termsandConditionsErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        termsandConditions: false,
      });
    }
  };

  /*** Card Details Handler  ***/
  const handleCardDetailsChange = (e) => {
    if (e.target.name == "nameoncard") {
      setCardDataError({
        ...cardDataError,
        nameoncardErr: "",
      });
    } else if (e.target.name == "securitycode") {
      setCardDataError({
        ...cardDataError,
        securitycodeErr: "",
      });

      if (!validator.isNumeric(e.target.value)) {
        setCardDataError({
          ...cardDataError,
          securitycodeErr: t(
            "checkoutPage.validationMsg.PleaseEnteronlyNumber"
          ),
        });
      }
    } else {
      setCardDataError({
        ...cardDataError,
        zipcodeErr: "",
      });
      if (!validator.isNumeric(e.target.value)) {
        setCardDataError({
          ...cardDataError,
          zipcodeErr: t("checkoutPage.validationMsg.PleaseEnteronlyNumber"),
        });
      }
    }

    setCardData({
      ...cardData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckExistingUser = React.useCallback(
    (e) => {
      if (userAuthData == null) {
        if (e.target.value !== "") {
          const data = {
            email: e.target.value,
            languageToShow: languageToShow,
          };

          validateUserEmailRequest(data);
        }
      }
    },
    [languageToShow]
  );

  React.useEffect(() => {
    if (validateUserEmail != null && userAuthData == null) {
      if (validateUserEmail?.data?.is_email_exist) {
        // emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: t(
            "checkoutPage.validationMsg.EmailAlreadyEexistsPleaseLogin"
          ),
        });
      } else {
        setuserDataError({
          ...userDataError,
          emailErr: "",
        });
      }
    }
  }, [languageToShow, validateUserEmail]);

  React.useEffect(() => {
    if (paymentModeValue === "card") {
      if (
        userData.firstname != "" &&
        userData.email != "" &&
        userData.confirmEmail != "" &&
        countryCode != "" &&
        userData.mobileNo != ""
      ) {
        setDisabledBidding(false);
        setShowCardTag(false);
      } else {
        setDisabledBidding(false);
        setShowCardTag(true);
      }
    } else {
      setDisabledBidding(false);
      setShowCardTag(false);
    }
    // hotelWheatherApiRequest(city);
  }, [userData, cardData, countryCode, paymentModeValue]);

  /*** Apply Wallet Balance Handler  ***/
  const applyWalletBal = (event) => {
    event.preventDefault();
    if (walletBal.length != 0) {
      setPointsApplied(!pointsApplied);
    }

    if (walletBal == "") {
      setWalletBalErr(
        t("checkoutPage.validationMsg.PleaseEnterYourWalletBalance")
      );
    } else {
      const postData = {
        room_type_id: bidCheckoutDetails?.data?.request_data?.room_type_id,
        hour: "24",
        no_of_rooms: bidCheckoutDetails?.data?.request_data?.no_of_rooms,
        no_of_points: walletBal,
        promo_code:
          promoCode != "" && applyHotelOffersFailedData?.code != 3014
            ? promoCode
            : "",
        bid_now_amount: biddingAmount,
        check_in_date: bidCheckoutDetails?.data?.request_data?.check_in_date,
        check_out_date: bidCheckoutDetails?.data?.request_data?.check_out_date,
      };
      if (pointsApplied) {
        delete postData.no_of_points;
        postData.remove_request = "no_of_points";
      }
      const data = {
        postData,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
      };
      applyHotelOffersRequest(data);
      if (pointsApplied) {
        setWalletBal("");
      } else {
        setWalletBal(walletBal);
      }
    }
  };

  useEffect(() => {
    if (walletBal.length == 0) {
      setWalletBalErr("");
    }
    if (biddingAmount.length == 0) {
      setBiddingAmountErr("");
    }
  }, [walletBal, biddingAmount]);

  /*** Bidding Handler  ***/
  const handleBiddingSubmit = (event) => {
    event.preventDefault();
    if (userAuthData == null) {
      setLoginPopupDialog(true);
      return;
    }
    if (userDataError?.firstnameErr) {
      firstnameRef?.current?.focus();
      return;
    } else if (userDataError.lastnameErr) {
      lastNameRef?.current?.focus();
      return;
    }
    if (biddingAmount == "") {
      setBiddingAmountErr(
        t("checkoutPage.validationMsg.PleaseEnterYourBidAmount")
      );

      return;
    } else if (biddingAmount < 0) {
      setBiddingAmountErr(
        t("checkoutPage.validationMsg.PleaseEnterYourValidBidAmount")
      );
      return;
    } else {
      setDisabledBiddingInput(true);
      setDisabledBidding(true);
      const postData = {
        room_type_id: bidCheckoutDetails?.data?.request_data?.room_type_id,
        hour: "24",
        no_of_rooms: bidCheckoutDetails?.data?.request_data?.no_of_rooms,
        no_of_points: walletBal == "" ? 0 : pointsApplied ? walletBal : 0,
        promo_code: promoCode == "" ? "" : promoCodeApplied ? promoCode : "",
        bid_now_amount: biddingAmount,
        check_in_date: bidCheckoutDetails?.data?.request_data?.check_in_date,
        check_out_date: bidCheckoutDetails?.data?.request_data?.check_out_date,
      };

      const data = {
        postData,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
      };
      applyHotelOffersRequest(data);
      stateClearAfterTask();
    }
  };

  /*** Apply Promo Code Handler  ***/
  const applyPromoCode = (event) => {
    event.preventDefault();
    setPromoCodeApplied(!promoCodeApplied);
    if (promoCode == "") {
      setPromoCodeErr(t("checkoutPage.validationMsg.PleaseEnterYourPromoCode"));
    } else {
      setPromoCodeErr("");

      const postData = {
        room_type_id: bidCheckoutDetails?.data?.request_data?.room_type_id,
        hour: "24",
        no_of_rooms: bidCheckoutDetails?.data?.request_data?.no_of_rooms,
        no_of_points:
          walletBal != "" &&
          bidCheckoutDetails?.data?.user_details?.wallet_balance_points > 0
            ? walletBal
            : 0,
        promo_code: promoCode,
        bid_now_amount: biddingAmount,
        check_in_date: bidCheckoutDetails?.data?.request_data?.check_in_date,
        check_out_date: bidCheckoutDetails?.data?.request_data?.check_out_date,
        email: saveGuestUserCheckoutData?.user_details?.email,
      };
      if (promoCodeApplied) {
        delete postData.promo_code;
        postData.remove_request = "promo_code";
      }
      const data = {
        postData,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
      };
      applyHotelOffersRequest(data);
      if (promoCodeApplied) {
        setPromoCode("");
      } else {
        setPromoCode(promoCode);
      }
    }
  };

  React.useEffect(() => {
    if (applyHotelOffers != null) {
      if (applyHotelOffers?.code != 3014 && promoCode != "") {
        setApplyHotelOfferState(applyHotelOffers);
      } else {
        setApplyHotelOfferState(null);
      }
    }
  }, [applyHotelOfferState]);

  React.useEffect(() => {
    if (applyHotelOffersFailedData != null) {
      if (applyHotelOffersFailedData?.code == 3019) {
        biddingAmountRef.current.focus();
        setDisabledBiddingInput(false);
        setDisabledBidding(false);
      } else if (applyHotelOffersFailedData?.code == 4070) {
        setPromoCodeApplied(false);
        setPromoCode("");
      } else if (applyHotelOffersFailedData?.code == 3014) {
        setPromoCodeApplied(false);
        setPromoCode("");
      } else if (applyHotelOffersFailedData?.code == 4045) {
        setPointsApplied(false);
        setWalletBal("");
      } else if (applyHotelOffersFailedData?.code == 3020) {
        setPointsApplied(false);
        setWalletBal("");
      } else if (applyHotelOffersFailedData?.code == 4081) {
        setPromoCodeApplied(false);
        setPromoCode("");
      } else {
      }
    }
  }, [applyHotelOffersFailedData]);

  React.useEffect(() => {
    if (applyHotelOffers != null) {
      if (applyHotelOffers?.success == true) {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    }
  }, [applyHotelOffers]);

  /*** Payment Type Handler  ***/
  const handlePaymentMode = (event) => {
    setPaymentModeValue(event.target.value);
  };

  var isEmailExist =
    validateUserEmail != null && userAuthData == null
      ? validateUserEmail?.data?.is_email_exist
        ? t("checkoutPage.validationMsg.EmailAlreadyEexistsPleaseLogin")
        : ""
      : null;

  /*** Confirm and Payment Booking Handler  ***/
  const handleConfirmPay = (e) => {
    e.preventDefault();
    const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    // /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
    const expectedArrTimeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/;
    const mobileNoError = MobileNumberValidator(
      userData.mobileNo,
      translatedMessageObj.MobileNumberValidatorMsgObj
    );
    const emailError = EmailValidator(
      userData.email,
      translatedMessageObj.EmailValidatorMsgObj
    );

    if (paymentModeValue === "cash") {
      if (userData.firstname == "") {
        firstnameRef.current.focus();
        setuserDataError({
          ...userDataError,
          firstnameErr: t(
            "checkoutPage.validationMsg.PleaseEnterYourFirstName"
          ),
        });
        return;
      } else if (userDataError.lastnameErr) {
        lastNameRef?.current?.focus();
        setuserDataError({
          ...userDataError,
          firstnameErr: t("checkoutPage.validationMsg.PleaseEnterYourLastName"),
        });
        return;
      } else if (emailError) {
        emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: emailError,
        });

        return;
      } else if (isEmailExist) {
        emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: isEmailExist,
        });
        return;
      } else if (userData.email != userData.confirmEmail) {
        confirmEmailRef.current.focus();
        setuserDataError({
          ...userDataError,
          confirmEmailErr: t(
            "checkoutPage.validationMsg.EmailandConfirmEmailNotSame"
          ),
        });
        return;
      } else if (countryCode == "") {
        // countryCodeRef.current.focus();
        setCountryCodeErr(
          t("checkoutPage.validationMsg.PleaseSelectYourcountrycode")
        );
        return;
      } else if (mobileNoError) {
        mobileNoRef.current.focus();
        setuserDataError({
          ...userDataError,
          mobileNoErr: mobileNoError,
        });
        return;
      } else if (cardData.termsandConditions == false) {
        setCardDataError({
          ...cardDataError,
          termsandConditionsErr: t(
            "checkoutPage.validationMsg.PleaseAcceptTermsandConditions"
          ),
        });
        return;
      } else {
        const cardDetailsObj = {
          card_number: "871492145677",
          name_on_card: "BUBAI SAHA",
          exp_date: "04/25",
          security_code: "073",
          card_type: "debit",
          zip_code: "700144",
        };
        const postData = {
          search_type: bidCheckoutDetails?.data?.request_data?.search_type,
          hotel_id: bidCheckoutDetails?.data?.request_data?.hotel_id,
          room_type_id: bidCheckoutDetails?.data?.request_data?.room_type_id,

          check_in_date: bidCheckoutDetails?.data?.request_data?.check_in_date,
          check_out_date:
            bidCheckoutDetails?.data?.request_data?.check_out_date,
          adults: bidCheckoutDetails?.data?.request_data?.adults,
          children: bidCheckoutDetails?.data?.request_data?.children,
          no_of_rooms: bidCheckoutDetails?.data?.request_data?.no_of_rooms,
          user_details: {
            first_name: userData.firstname,
            last_name: userData.lastname,
            email: userData.email,
            country_code: countryCode,
            contact_no: userData.mobileNo,
            expected_time_of_arrival: userData.expecedTimeArrival,
          },

          no_of_points:
            walletBal != "" &&
            bidCheckoutDetails?.data?.user_details?.wallet_balance_points > 0
              ? walletBal
              : 0,

          promo_code:
            promoCode != "" && applyHotelOffersFailedData?.code != 3014
              ? promoCode
              : "",
          bid_now_amount:
            biddingAmount != null || biddingAmount != "" ? biddingAmount : 0,
          pay_type: paymentModeValue,
          card_details: cardDetailsObj,
          payment_summery_details:
            applyHotelOffers != null
              ? applyHotelOffers?.data
              : bidCheckoutDetails?.data?.payment_summery_details,

          device_type: "web",
        };
        const data = {
          postData,
          languageToShow: languageToShow,
          token: userAuthData != null ? userAuthData.token : "",
        };
        if (applyHotelOffers != null) {
          if (applyHotelOffers?.success == true) {
            hotelBookingRequest(data);
            stateClearAfterTask();
            validateEmailStateClearAfterTask();
          } else {
            // errorToast("Apply Bidding First");
          }
        } else {
          MySwal.fire({
            confirmButtonText: "OK",
            confirmButtonColor: "#296da9",
            text: t(
              "checkoutPage.validationMsg.PleaseAddYourBiddingAmountBeforeBooking"
            ),
          });
        }
      }
    } else {
      if (userData.firstname == "") {
        firstnameRef.current.focus();
        setuserDataError({
          ...userDataError,
          firstnameErr: t(
            "checkoutPage.validationMsg.PleaseEnterYourFirstName"
          ),
        });
        return;
      } else if (emailError) {
        emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: emailError,
        });
        return;
      } else if (isEmailExist) {
        emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: isEmailExist,
        });
        return;
      } else if (userData.email != userData.confirmEmail) {
        confirmEmailRef.current.focus();
        setuserDataError({
          ...userDataError,
          confirmEmailErr: t(
            "checkoutPage.validationMsg.EmailandConfirmEmailNotSame"
          ),
        });
        return;
      } else if (countryCode == "") {
        setCountryCodeErr(
          t("checkoutPage.validationMsg.PleaseSelectYourcountrycode")
        );
        return;
      } else if (mobileNoError) {
        mobileNoRef.current.focus();
        setuserDataError({
          ...userDataError,
          mobileNoErr: mobileNoError,
        });
        return;
      } else if (cardData.termsandConditions == false) {
        setCardDataError({
          ...cardDataError,
          termsandConditionsErr: t(
            "checkoutPage.validationMsg.PleaseAcceptTermsandConditions"
          ),
        });
        return;
      } else {
        const cardDetailsObj = {
          card_number: cardData.cardnumber.replaceAll(/\s/g, ""),
          name_on_card: cardData.nameoncard,
          exp_date: cardData.expirydate,
          security_code: cardData.securitycode,
          card_type: cardData.selectedCardType,
          zip_code: cardData.zipcode,
        };
        const postData = {
          search_type: bidCheckoutDetails?.data?.request_data?.search_type,
          hotel_id: bidCheckoutDetails?.data?.request_data?.hotel_id,
          room_type_id: bidCheckoutDetails?.data?.request_data?.room_type_id,

          check_in_date: bidCheckoutDetails?.data?.request_data?.check_in_date,
          check_out_date:
            bidCheckoutDetails?.data?.request_data?.check_out_date,
          adults: bidCheckoutDetails?.data?.request_data?.adults,
          children: bidCheckoutDetails?.data?.request_data?.children,
          no_of_rooms: bidCheckoutDetails?.data?.request_data?.no_of_rooms,
          user_details: {
            first_name: userData.firstname,
            last_name: userData.lastname,
            email: userData.email,
            country_code: countryCode,
            contact_no: userData.mobileNo,
            expected_time_of_arrival: userData.expecedTimeArrival,
          },

          no_of_points:
            walletBal != "" &&
            bidCheckoutDetails?.data?.user_details?.wallet_balance_points > 0
              ? walletBal
              : 0,

          promo_code:
            promoCode != "" && applyHotelOffersFailedData?.code != 3014
              ? promoCode
              : "",
          bid_now_amount:
            biddingAmount != null || biddingAmount != "" ? biddingAmount : 0,
          pay_type: paymentModeValue,
          card_details: cardDetailsObj,
          payment_summery_details: {
            currency:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.currency
                : bidCheckoutDetails?.data?.payment_summery_details?.currency,
            net_rate:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.net_rate
                : bidCheckoutDetails?.data?.payment_summery_details?.net_rate,
            taxes:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.taxes
                : bidCheckoutDetails?.data?.payment_summery_details?.taxes,
            municipal_taxes:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.municipal_taxes
                : bidCheckoutDetails?.data?.payment_summery_details
                    ?.municipal_taxes,

            service_fee:
              applyHotelOffers != null &&
              applyHotelOffers?.data?.service_fee != null
                ? applyHotelOffers?.data?.service_fee
                : bidCheckoutDetails?.data?.payment_summery_details
                    ?.service_fee != null
                ? bidCheckoutDetails?.data?.payment_summery_details?.service_fee
                : 0,

            amount:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.amount
                : bidCheckoutDetails?.data?.payment_summery_details?.amount,
            amount_to_be_paid_now:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.amount_to_be_paid_now
                : bidCheckoutDetails?.data?.payment_summery_details
                    ?.amount_to_be_paid_now,
          },
          device_type: "web",
        };
        const data = {
          postData,
          languageToShow: languageToShow,
          token: userAuthData != null ? userAuthData.token : "",
        };
        if (applyHotelOffers != null) {
          if (applyHotelOffers?.success == true) {
            hotelBookingRequest(data);
            //    stateClearAfterTask();
            validateEmailStateClearAfterTask();
          } else {
            // errorToast("Apply Bidding First");
          }
        } else {
          MySwal.fire({
            confirmButtonText: "OK",
            confirmButtonColor: "#296da9",
            text: t(
              "checkoutPage.validationMsg.PleaseAddYourBiddingAmountBeforeBooking"
            ),
          });
        }
      }
    }
  };

  React.useEffect(() => {
    if (hotelBooking != null) {
      if (hotelBooking.code == 4036 || hotelBooking.code == 4054) {
        const timeout = setTimeout(() => {
          navigate("/");
          saveGuestUserCheckoutDataRequest(null);
          stateClearAfterTask();
        }, 5001);
        return () => clearTimeout(timeout);
      } else if (
        hotelBooking.code == 4037 ||
        hotelBooking.code == 4039 ||
        hotelBooking.code == 4050
      ) {
        biddingAmountRef.current.focus();
        setDisabledBiddingInput(false);
        setDisabledBidding(false);
      } else {
      }
    }
    // refreshPage();
  }, [JSON.stringify(hotelBooking)]);
  React.useEffect(() => {
    if (hotelBooking != null) {
      if (hotelBooking.success == true) {
        window.open(`${hotelBooking.data.url}`, "_self");

        saveGuestUserCheckoutDataRequest(null);
        // stateClearAfterTask();
        // } else if (
        //   hotelBooking.code == 4037 ||
        //   hotelBooking.code == 4039 ||
        //   hotelBooking.code == 4050
        // ) {
        //   // errorToast(hotelBooking.message);
        //   biddingAmountRef.current.focus();
        //   setDisabledBiddingInput(false);
        //   setDisabledBidding(false);
      } else {
      }
      // stateClearAfterTask();
    }
  }, [JSON.stringify(hotelBooking)]);

  const viewBookingDetailsHandler = () => {
    setShowBookingConfirmModal(true);
    setShowConfirmDialog(false);
  };

  const showConfirmationPopup = () => {
    setShowBookingConfirmModal(true);
  };
  useEffect(() => {
    if (currentUser != null) {
      handleCloseLoginPopup();
    }
  }, [signUpmessage, currentUser]);

  /*** View Promo Code Handler  ***/
  const viewPromoCode = () => {
    setViewPromoList(true);
  };
  const promoCodeListHide = () => {
    setViewPromoList(false);
  };
  const handleCloseBookingConditionPopup = () => {
    setBookingConditionShow(false);
  };
  const handleShowBookingConditionPopup = () => setBookingConditionShow(true);
  return (
    <>
      {/* //////////Checkout main content start/////////// */}
      <div className="container">
        <div className="WL_biduser_mainwrp">
          <div className="row">
            {/* ///Checkout left content start/// */}
            <div className="col-md-4">
              <div className="WL_left-bibuser custom-leftbid extramarge">
                <h3 className="WL_bookingheading">
                  {t("checkoutPage.YourBookingDetails")}
                </h3>

                <div className="WL_inner_leftwrp">
                  {/* ///checkout checkin wrp start/// */}
                  <div className="WL_checkwrp">
                    <div className="WL_checkwrp_left">
                      <p className="WL_check_txt">
                        {t("checkoutPage.CheckIn")}
                      </p>
                      <p className="WL_check_txt2">
                        {moment(
                          new Date(
                            bidCheckoutDetails?.data?.booking_details?.check_in_date
                          )
                          // , "DD/MM/YYYY"
                        ).format("ddd, ll")}
                      </p>
                      <p className="WL_check_txt">
                        {/* 12:00 AM */}
                        {moment(
                          `${bidCheckoutDetails?.data?.booking_details?.check_in_time}`,
                          ["hh:mm"]
                        ).format("hh:mm A")}{" "}
                      </p>
                    </div>

                    <div className="WL_checkwrp_left">
                      <p className="WL_check_txt">
                        {t("checkoutPage.CheckOut")}
                      </p>
                      <p className="WL_check_txt2">
                        {moment(
                          new Date(
                            bidCheckoutDetails?.data?.booking_details?.check_out_date
                          )
                          // ,"DD/MM/YYYY"
                        ).format("ddd, ll")}
                      </p>
                      <p className="WL_check_txt">
                        {/* 11:00 AM */}
                        {moment(
                          `${bidCheckoutDetails?.data?.booking_details?.check_out_time}`,
                          ["hh:mm"]
                        ).format("hh:mm A")}{" "}
                      </p>
                    </div>
                  </div>
                  {/* ///checkout checkin wrp end/// */}

                  {/* ///Total length wrp start/// */}
                  <div className="WL_totl_textwrp">
                    <p className="WL_totl_text">
                      <span>{t("checkoutPage.TotalStay")}:</span>{" "}
                      <strong>
                        {
                          bidCheckoutDetails?.data?.booking_details
                            ?.number_of_nights
                        }{" "}
                        {bidCheckoutDetails?.data?.booking_details
                          ?.number_of_nigh > 1
                          ? t("checkoutPage.Nights")
                          : t("checkoutPage.Night")}
                      </strong>
                    </p>
                    <p className="WL_totl_text">
                      <span>{t("checkoutPage.NoofRoom")}:</span>{" "}
                      <strong>
                        {bidCheckoutDetails?.data?.request_data.no_of_rooms}{" "}
                        {bidCheckoutDetails?.data?.request_data.no_of_rooms > 1
                          ? t("checkoutPage.Rooms")
                          : t("checkoutPage.Room")}
                      </strong>
                    </p>
                    <p className="WL_totl_text">
                      <span>{t("checkoutPage.RoomType")}:</span>{" "}
                      <strong>
                        {
                          bidCheckoutDetails?.data?.booking_details
                            ?.room_type_name
                        }
                      </strong>
                    </p>
                    <p className="WL_totl_text">
                      {/* Fits up to: */}
                      <span>{t("checkoutPage.NoofGuest")}:</span>{" "}
                      <strong>
                        {bidCheckoutDetails?.data?.request_data.adults}{" "}
                        {bidCheckoutDetails?.data?.request_data.adults > 1
                          ? t("checkoutPage.Guests")
                          : t("checkoutPage.Guest")}
                      </strong>
                    </p>
                  </div>
                  {/* ///Total length wrp end/// */}

                  {/* ////////////////////Available balance wrp start/////////////// */}

                  {/* ///Bid amount wrp start/// */}
                  {userAuthData != null && (
                    <div className="WL_bidamnt_wrp">
                      <div className="WL_amnt_textheading">
                        {t("checkoutPage.AvailableWalletBalance")}
                      </div>
                      <div className="WL_pointbox">
                        {
                          bidCheckoutDetails?.data?.user_details
                            ?.wallet_balance_points
                        }{" "}
                        {t("checkoutPage.POINTS")}
                      </div>
                      <div className="d-flex justify-content-start w-100">
                        <p className="WL_totl_text_conversion">
                          (
                          <span>
                            {currencyToShow.current === "INR"
                              ? Number(
                                  bidCheckoutDetails?.data
                                    ?.wallet_points_conversion_rate
                                    ?.wallet_point_value / 22
                                ).toFixed(2)
                              : bidCheckoutDetails?.data
                                  ?.wallet_points_conversion_rate
                                  ?.wallet_point_value}{" "}
                            {t("checkoutPage.Point")}
                          </span>{" "}
                          ={" "}
                          <span>
                            {Number(
                              bidCheckoutDetails?.data
                                ?.wallet_points_conversion_rate
                                ?.conversion_amount *
                                currencyToShow.convertedRates
                            ).toFixed(2)}{" "}
                            {currencyToShow.current}
                          </span>
                          )
                        </p>
                      </div>
                    </div>
                  )}

                  {/* ///Bid amount wrp end/// */}

                  {/* ///Card details wrp start/// */}
                  {userAuthData != null && (
                    <div className="WL_carddetailst_wrp customform">
                      <Form onSubmit={applyWalletBal}>
                        <div className="WL_innercardholder">
                          <Form.Group controlId="formCheckoutCardDetails">
                            <Form.Control
                              type="text"
                              placeholder={t("checkoutPage.EnterwalletBalance")}
                              className="WL_frm_wcrd WL_frm_wcrd_points"
                              name="walletBal"
                              onChange={(e) => {
                                setWalletBal(e.target.value);
                                if (!validator.isNumeric(e.target.value)) {
                                  setWalletBalErr(
                                    t(
                                      "checkoutPage.validationMsg.PleaseEnteronlyNumber"
                                    )
                                  );
                                  return;
                                } else {
                                  setWalletBalErr("");
                                }
                              }}
                              value={walletBal}
                              disabled={
                                bidCheckoutDetails?.data?.user_details
                                  ?.wallet_balance_points > 0
                                  ? pointsApplied
                                    ? true
                                    : false
                                  : true
                              }
                            />
                            <Form.Text className="text-muted">
                              {walletBalErr}
                            </Form.Text>
                          </Form.Group>
                          <Button
                            variant=""
                            type="submit"
                            className="WL_card_submitbtn"
                            disabled={disabledBtn}
                          >
                            {pointsApplied
                              ? t("checkoutPage.remove")
                              : t("checkoutPage.apply")}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  )}
                  {/* ///card details wrp end/// */}
                  {/* ////////////////////Available balance wrp end/////////////// */}

                  {/* ///Total summary wrp start/// */}

                  <div className="WL_paymentwrp">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h3>
                          {t("checkoutPage.PaymentSummary.PaymentSummary")}
                        </h3>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* Payment Summar Calculation Part */}
                        <div className="WL_payment_inerwrp">
                          <div className="WL_payment_iner_left">
                            {t("checkoutPage.PaymentSummary.Netrate")}:
                          </div>

                          <div className="WL_payment_iner_right">
                            {applyHotelOffers !== null &&
                            "data" in applyHotelOffers &&
                            "net_rate" in applyHotelOffers.data
                              ? Number(
                                  applyHotelOffers.data.net_rate *
                                    currencyToShow.convertedRates
                                ).toFixed(2)
                              : ""}
                            {applyHotelOffers === null &&
                            bidCheckoutDetails &&
                            "data" in bidCheckoutDetails &&
                            "payment_summery_details" in
                              bidCheckoutDetails.data &&
                            "net_rate" in
                              bidCheckoutDetails.data.payment_summery_details
                              ? parseInt(
                                  bidCheckoutDetails.data
                                    .payment_summery_details.net_rate *
                                    currencyToShow.convertedRates
                                )
                              : ""}
                            {" " + currencyToShow.current}
                          </div>
                        </div>

                        <div className="WL_payment_inerwrp">
                          <div className="WL_payment_iner_left">
                            {t("checkoutPage.PaymentSummary.vat15per")}:{" "}
                            {`(${
                              applyHotelOffers !== null
                                ? applyHotelOffers.data.is_tax_percentage
                                  ? applyHotelOffers.data.tax_amount
                                  : "15"
                                : "15"
                            }%)`}
                          </div>
                          <div className="WL_payment_iner_right">
                            {applyHotelOffers !== null &&
                            "data" in applyHotelOffers &&
                            "taxes" in applyHotelOffers.data
                              ? Number(
                                  applyHotelOffers.data.taxes *
                                    currencyToShow.convertedRates
                                ).toFixed(2)
                              : ""}
                            {applyHotelOffers === null &&
                            bidCheckoutDetails &&
                            "data" in bidCheckoutDetails &&
                            "payment_summery_details" in
                              bidCheckoutDetails.data &&
                            "taxes" in
                              bidCheckoutDetails.data.payment_summery_details
                              ? parseInt(
                                  bidCheckoutDetails.data
                                    .payment_summery_details.taxes *
                                    currencyToShow.convertedRates
                                )
                              : ""}
                            {" " + currencyToShow.current}
                          </div>
                        </div>

                        {/******  municipal taxes**** */}
                        <div className="WL_payment_inerwrp_municiplity">
                          <div className="WL_payment_inerwrp">
                            <div className="WL_payment_iner_left">
                              {t("checkoutPage.PaymentSummary.Municipality")}{" "}
                              {`(${
                                applyHotelOffers !== null
                                  ? applyHotelOffers.data
                                      .is_municipal_tax_percentage
                                    ? applyHotelOffers.data.municipal_tax_amount
                                    : "5"
                                  : "5"
                              }%)`}
                            </div>
                            <div className="WL_payment_iner_right">
                              {applyHotelOffers !== null &&
                              "data" in applyHotelOffers &&
                              "municipal_taxes" in applyHotelOffers.data
                                ? Number(
                                    applyHotelOffers.data.municipal_taxes *
                                      currencyToShow.convertedRates
                                  ).toFixed(2)
                                : ""}
                              {applyHotelOffers === null &&
                              bidCheckoutDetails &&
                              "data" in bidCheckoutDetails &&
                              "payment_summery_details" in
                                bidCheckoutDetails.data &&
                              "municipal_tax" in
                                bidCheckoutDetails.data.payment_summery_details
                                ? parseInt(
                                    bidCheckoutDetails.data
                                      .payment_summery_details.municipal_tax *
                                      currencyToShow.convertedRates
                                  )
                                : ""}
                              {" " + currencyToShow.current}
                            </div>
                          </div>
                        </div>
                        {/******  municipal taxes**** */}

                        {/* Coupon applied */}
                        {applyHotelOffers != null
                          ? applyHotelOffers?.data?.coupon_amount_applied && (
                              <div className="WL_payment_inerwrp">
                                <div className="WL_payment_iner_left">
                                  <strong>
                                    {t(
                                      "checkoutPage.PaymentSummary.AppliedCouponAmount"
                                    )}
                                    :
                                  </strong>{" "}
                                </div>
                                <div className="WL_payment_iner_right">
                                  <strong>
                                    {applyHotelOffers !== null &&
                                    "data" in applyHotelOffers &&
                                    "coupon_amount_applied" in
                                      applyHotelOffers.data
                                      ? Number(
                                          applyHotelOffers.data
                                            .coupon_amount_applied *
                                            currencyToShow.convertedRates
                                        ).toFixed(2)
                                      : ""}

                                    {" " + currencyToShow.current}
                                  </strong>
                                </div>
                              </div>
                            )
                          : null}
                        {applyHotelOffers === null &&
                        bidCheckoutDetails &&
                        "data" in bidCheckoutDetails &&
                        "payment_summery_details" in bidCheckoutDetails.data &&
                        "service_fee" in
                          bidCheckoutDetails.data.payment_summery_details ? (
                          <div className="WL_payment_inerwrp">
                            <div className="WL_payment_iner_left">
                              {t("checkoutPage.PaymentSummary.Servicefee")}:
                            </div>
                            <div className="WL_payment_iner_right">
                              {parseInt(
                                bidCheckoutDetails.data.payment_summery_details
                                  .service_fee * currencyToShow.convertedRates
                              )}
                              {" " + currencyToShow.current}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {applyHotelOffers !== null ? (
                          <div className="WL_payment_inerwrp">
                            <div className="WL_payment_iner_left">
                              {t("checkoutPage.PaymentSummary.Servicefee")}:
                            </div>

                            <div className="WL_payment_iner_right">
                              {applyHotelOffers !== null
                                ? Number(
                                    applyHotelOffers.data.service_fee *
                                      currencyToShow.convertedRates
                                  ).toFixed(2)
                                : ""}
                              {" " + currencyToShow.current}
                            </div>
                          </div>
                        ) : null}

                        {/* Wallet balance applied */}
                        {applyHotelOffers != null
                          ? applyHotelOffers?.data?.wallet_balance_applied && (
                              <div className="WL_payment_inerwrp">
                                <div className="WL_payment_iner_left">
                                  <strong>
                                    {t(
                                      "checkoutPage.PaymentSummary.AppliedWalletBalance"
                                    )}
                                    &nbsp;
                                  </strong>{" "}
                                  {}
                                </div>
                                <div className="WL_payment_iner_right">
                                  <strong>
                                    {applyHotelOffers !== null &&
                                    "data" in applyHotelOffers &&
                                    "wallet_balance_applied" in
                                      applyHotelOffers.data
                                      ? Number(
                                          applyHotelOffers.data
                                            .wallet_balance_applied *
                                            currencyToShow.convertedRates
                                        ).toFixed(2)
                                      : ""}

                                    {" " + currencyToShow.current}
                                  </strong>
                                </div>
                              </div>
                            )
                          : null}

                        {paymentModeValue == "cash" ? (
                          <div className="WL_payment_inerwrp">
                            <div className="WL_payment_iner_left">
                              <strong>
                                {t(
                                  "checkoutPage.PaymentSummary.AmountToBePaidNow"
                                )}
                              </strong>{" "}
                            </div>
                            <div className="WL_payment_iner_right">
                              <strong>
                                {applyHotelOffers !== null &&
                                "data" in applyHotelOffers &&
                                "amount_to_be_paid_now" in applyHotelOffers.data
                                  ? Number(
                                      applyHotelOffers.data
                                        .amount_to_be_paid_now *
                                        currencyToShow.convertedRates
                                    ).toFixed(2) -
                                    Number(applyHotelOffers.data.service_fee)
                                  : ""}
                                {applyHotelOffers === null &&
                                bidCheckoutDetails &&
                                "data" in bidCheckoutDetails &&
                                "payment_summery_details" in
                                  bidCheckoutDetails.data &&
                                "amount_to_be_paid_now" in
                                  bidCheckoutDetails.data
                                    .payment_summery_details
                                  ? parseInt(
                                      bidCheckoutDetails.data
                                        .payment_summery_details
                                        .amount_to_be_paid_now *
                                        currencyToShow.convertedRates
                                    )
                                  : ""}
                                {" " + currencyToShow.current}
                              </strong>
                            </div>
                          </div>
                        ) : null}
                      </AccordionDetails>
                      {/* Payment Summar Calculation Part */}
                    </Accordion>
                    <div className="WL_payment_inerwrp total-payment">
                      <div className="WL_payment_iner_left">
                        <strong>
                          {t("checkoutPage.PaymentSummary.Amount")}
                        </strong>{" "}
                      </div>
                      <div className="WL_payment_iner_right">
                        <strong>
                          {paymentModeValue == "cash" &&
                          applyHotelOffers !== null
                            ? Number(
                                applyHotelOffers.data.service_fee *
                                  currencyToShow.convertedRates
                              ).toFixed(2)
                            : ""}

                          {paymentModeValue == "card" &&
                          applyHotelOffers !== null
                            ? Number(
                                applyHotelOffers.data.amount_to_be_paid_now *
                                  currencyToShow.convertedRates
                              ).toFixed(2)
                            : ""}

                          {applyHotelOffers === null &&
                          bidCheckoutDetails &&
                          "data" in bidCheckoutDetails &&
                          "payment_summery_details" in
                            bidCheckoutDetails.data &&
                          "amount" in
                            bidCheckoutDetails.data.payment_summery_details
                            ? parseInt(
                                bidCheckoutDetails.data.payment_summery_details
                                  .amount * currencyToShow.convertedRates
                              )
                            : ""}
                          {" " + currencyToShow.current}
                        </strong>
                      </div>
                    </div>
                  </div>
                  {/* ///Total summary wrp end/// */}

                  {/* ///Bid amount wrp start/// */}
                  <div className="WL_bidamnt_wrp">
                    <div className="WL_amnt_textheading">
                      {t("checkoutPage.BidYourAmount")}{" "}
                      <Tooltip
                        title={t("checkoutPage.InfoModalPopup.BiddingRules")}
                        placement="top"
                        arrow
                      >
                        <span className="bidInfo" onClick={handleShow}>
                          {" "}
                          <BsInfoCircle />
                        </span>
                      </Tooltip>
                    </div>
                    {showCardTag == true && (
                      <div className="WL_entr_boxblue">
                        {t("checkoutPage.EnterCardDetailsBeforeBidding")}
                      </div>
                    )}
                  </div>

                  {/* ///Bid amount wrp end/// */}

                  {/* ///Card details wrp start/// */}
                  <div className="WL_carddetailst_wrp customform">
                    <Form
                      onSubmit={handleBiddingSubmit}
                      className={"checkoutForm"}
                    >
                      <div className="WL_innercardholder">
                        <Form.Group controlId="formCheckoutCardDet">
                          <Form.Control
                            type="text"
                            placeholder={currencyToShow.current + " 500"}
                            className="WL_frm_wcrd WL_frm_wcrd_points"
                            name="biddingAmount"
                            onChange={(e) => {
                              setBiddingAmount(e.target.value);
                              if (!validator.isNumeric(e.target.value)) {
                                setBiddingAmountErr(
                                  t(
                                    "checkoutPage.validationMsg.PleaseEnteronlyNumber"
                                  )
                                );
                                return;
                              } else {
                                setBiddingAmountErr("");
                              }
                            }}
                            value={biddingAmount}
                            disabled={disabledBiddingInput}
                            ref={biddingAmountRef}
                          />
                          <Form.Text className="text-muted">
                            {biddingAmountErr}
                          </Form.Text>
                        </Form.Group>

                        <Button
                          variant=""
                          type="submit"
                          className="WL_card_submitbtn"
                          disabled={disabledBidding}
                        >
                          {t("checkoutPage.SUBMIT")}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            {/* ///Checkout left content end/// */}

            {/* ///Checkout right content start/// */}
            <div className="col-md-8">
              {/* ///review slider content start/// */}
              <CheckoutHotelDetails
                CheckoutHotelDetailsData={bidCheckoutDetails}
              />
              {/* ///review slider content end/// */}
              <Form onSubmit={handleConfirmPay}>
                {/* ///personal details start/// */}
                <CheckoutUserForm
                  userData={userData}
                  userDataError={userDataError}
                  handlePersonalDetailsChange={handlePersonalDetailsChange}
                  handleCountryCodeChange={handleCountryCodeChange}
                  countryCode={countryCode}
                  countryCodeErr={countryCodeErr}
                  firstnameRef={firstnameRef}
                  lastNameRef={lastNameRef}
                  confirmEmailRef={confirmEmailRef}
                  emailRef={emailRef}
                  countryCodeRef={countryCodeRef}
                  mobileNoRef={mobileNoRef}
                  chandleChangeExpectedTime={handleChangeTimePicker}
                  expectedTimeArrRef={expectedTimeArrRef}
                  handleCheckExistingUser={handleCheckExistingUser}
                  isEmailExits={validateUserEmail?.data?.is_email_exist}
                  handleShowLoginPopup={handleShowLoginPopup}
                  userAuthData={userAuthData}
                />
                {/* ///personal details  end/// */}

                {/* ///promo code wrapper start/// */}
                <div className="WL_persnal_wrap customform">
                  {/* ///promo code start/// */}
                  <h3 className="WL_amnt_textheading">
                    {t("checkoutPage.DoyouHaveaPromoCode")}
                  </h3>
                  <div className="WL_carddetailst_wrp customform">
                    <div className="WL_innercardholder">
                      <Form.Group controlId="formCheckoutCardPromo">
                        <Form.Control
                          type="text"
                          placeholder={t("checkoutPage.TEST1234")}
                          className="WL_frm_prmcode"
                          name="promoCode"
                          onChange={(e) => {
                            setPromoCode(e.target.value.toUpperCase());
                            if (e.target.value != "") {
                              setPromoCodeErr("");
                              return;
                            }
                          }}
                          value={promoCode}
                          disabled={promoCodeApplied}
                        />
                        <Form.Text className="text-muted">
                          {promoCodeErr}
                        </Form.Text>
                      </Form.Group>
                      <Button
                        variant=""
                        // type="submit"
                        className="WL_card_submitbtn"
                        onClick={applyPromoCode}
                        disabled={
                          promoCode == "" || disabledBtn == true ? true : false
                        }
                      >
                        {promoCodeApplied
                          ? t("checkoutPage.remove")
                          : t("checkoutPage.apply")}
                      </Button>
                    </div>
                  </div>

                  <div className="WL_paid_wrp">
                    <div className="WL_textleft">
                      {t("checkoutPage.PaymentSummary.TotalAmount")}
                    </div>

                    <div className="WL_textright">
                      {applyHotelOffers !== null &&
                      "data" in applyHotelOffers &&
                      "amount_to_be_paid_now" in applyHotelOffers.data
                        ? Number(
                            applyHotelOffers.data.amount_to_be_paid_now *
                              currencyToShow.convertedRates
                          ).toFixed(2)
                        : ""}
                      {applyHotelOffers === null &&
                      bidCheckoutDetails &&
                      "data" in bidCheckoutDetails &&
                      "payment_summery_details" in bidCheckoutDetails.data &&
                      "amount_to_be_paid_now" in
                        bidCheckoutDetails.data.payment_summery_details
                        ? parseInt(
                            bidCheckoutDetails.data.payment_summery_details
                              .amount_to_be_paid_now *
                              currencyToShow.convertedRates
                          )
                        : ""}
                      {" " + currencyToShow.current}
                    </div>
                  </div>
                  {/* ///amount paid display end/// */}

                  <h4 className="WL_personalheading">
                    {t("checkoutPage.PayNow")}{" "}
                  </h4>

                  <div className="WL_creditradio_mwrp">
                    {/* <Form> */}
                    <Form.Group
                      className="WL-custmpayradio"
                      controlId="formPayRadio"
                    >
                      {bidCheckoutDetails?.data?.hotel_details
                        ?.payment_options &&
                        bidCheckoutDetails?.data?.hotel_details?.payment_options.some(
                          (option) => option.includes("Online")
                        ) && (
                          <Form.Check
                            type="radio"
                            checked={paymentModeValue === "card"}
                            name="paynowcreditcard"
                            id="paynowcreditcard"
                            label={t("checkoutPage.CreditCardorDebitCard")}
                            onChange={handlePaymentMode}
                            value={"card"}
                          />
                        )}

                      {bidCheckoutDetails?.data?.hotel_details
                        ?.payment_options &&
                        bidCheckoutDetails?.data?.hotel_details?.payment_options.some(
                          (option) => option.includes("Check-in")
                        ) &&
                        bidCheckoutDetails?.data
                          ?.is_offline_payment_available == true && (
                          <Form.Check
                            type="radio"
                            name="paynowcreditcard"
                            id="paynowathotel"
                            label={t("checkoutPage.PayatHotel")}
                            checked={paymentModeValue === "cash"}
                            value={"cash"}
                            onChange={handlePaymentMode}
                          />
                        )}
                    </Form.Group>
                    {/* </Form> */}
                  </div>
                  <div className="WL_cc_cardicon">
                    {/* <img src="./img/Paypal.svg" alt="" /> */}
                    <img
                      src={UnionPay}
                      height="54"
                      width="54"
                      style={{ height: "auto" }}
                      alt=""
                      className="unionPayLogo"
                    />
                    <img src="./img/Visa.svg" alt="" />
                    <img src="./img/mastercard.svg" alt="" />
                    <img src="./img/mada.svg" alt="" />
                    <img
                      src={applepay}
                      height="54"
                      width="54"
                      style={{ height: "auto" }}
                      alt=""
                    />
                    <img
                      src={"./img/samsung-pay-logo.svg"}
                      height="54"
                      width="54"
                      style={{ margin: "0 !important", height: "17px" }}
                      alt=""
                      className="samsungLogo"
                    />

                    {/* <img src="./img/Stc_pay.svg" alt="" /> */}
                  </div>

                  <div className="WL_creditfrmcont_mwrp">
                    {/* <Form> */}
                    {paymentModeValue === "cash" ? "" : <></>}
                    <Form.Group
                      className="form-checkbox WL_checkbx_crdcard"
                      controlId="formCheckoutAgree"
                    >
                      <Form.Check
                        type="checkbox"
                        className="checkout_checkbox"
                        label={
                          <>
                            {t("checkoutPage.IagreeToThe")}{" "}
                            {/* <Link to="#"> */}
                            <span
                              style={{
                                cursor: "pointer",
                                fontWeight: "600",
                                color: "#374a77",
                              }}
                              onClick={handleShowBookingConditionPopup}
                            >
                              {t("checkoutPage.bookingcondition")}
                            </span>
                            {/* </Link> */}{" "}
                            <Link to="/terms&conditions">
                              {t("checkoutPage.TermsandConditions")}
                            </Link>
                            {/* {t("checkoutPage.and")}{" "} */}
                            <Link to="/privacy-policy">
                              {t("checkoutPage.PrivacyPolicies")}
                            </Link>{" "}
                            {t("checkoutPage.and")}{" "}
                            <Link to="/safety-security">
                              {t("checkoutPage.SafetyandSecurity")}
                            </Link>{" "}
                            {t("checkoutPage.of")}{" "}
                            {t("checkoutPage.Wfrleedotcom")}{" "}
                          </>
                        }
                        checked={cardData.termsandConditions}
                        onChange={handletermspolicy}
                      />
                      <br />
                      <Form.Text className="text-muted error-text">
                        {cardDataError.termsandConditionsErr}
                      </Form.Text>
                    </Form.Group>
                    <Button
                      variant=""
                      type="submit"
                      className="formsubmit"
                      // disabled={disabledBtn}
                    >
                      {hotelBookingLoading ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        t("checkoutPage.ConfirmandPay")
                      )}
                      {/* Confirm and Pay */}
                    </Button>
                    {/* </Form> */}
                  </div>
                </div>

                {/* ///promo code wrapper  end/// */}
              </Form>
            </div>
            {/* ///Checkout right content start/// */}
          </div>
        </div>
        <ConfirmDialog
          setShowConfirmDialog={setShowConfirmDialog}
          isOpen={showConfirmDialog}
          caption={
            hotelBooking != null
              ? hotelBooking?.data != null
                ? t("checkoutPage.BookingConfirmedSuccessfully")
                : hotelBooking?.message
              : ""
          }
          description={
            <>
              {hotelBooking != null &&
              hotelBooking.success == true &&
              hotelBooking?.data != null ? (
                <p>
                  {t("checkoutPage.YourBookingNoIs")}{" "}
                  <strong>
                    {hotelBooking != null
                      ? hotelBooking?.data?.booking_number
                      : ""}
                  </strong>{" "}
                  {t("checkoutPage.forhotel")}{" "}
                  <strong>
                    {hotelBooking != null
                      ? hotelBooking?.data?.hotel_details?.name
                      : ""}
                  </strong>
                </p>
              ) : (
                ""
              )}
            </>
          }
          confirmButtonLabel={t("checkoutPage.ViewBookingDetails")}
          isConfirmed={
            hotelBooking != null &&
            hotelBooking.success == true &&
            hotelBooking?.data != null
              ? true
              : false
          }
          continueButtonLabel={t("checkoutPage.GotoHome")}
          confirmButtonHandler={viewBookingDetailsHandler}
          bookingData={hotelBooking != null ? hotelBooking : null}
          cancelButtonHandler={() => {
            setShowConfirmDialog(false);
            navigate("/");
            stateClearAfterTask();
          }}
        />

        <InfoModal
          show={show}
          handleClose={handleClose}
          biddingRule={bidCheckoutDetails?.data?.biding_rules}
        />
        <LoginModal
          show={showLoginPopup}
          handleClose={handleCloseLoginPopup}
          search_type={bidCheckoutDetails?.data?.request_data?.search_type}
        >
          <LoginPage
            handleCloseLoginPopup={handleCloseLoginPopup}
            search_type={bidCheckoutDetails?.data?.request_data?.search_type}
          />
        </LoginModal>
        <BookingConditionModal
          show={bookingConditionShow}
          handleClose={handleCloseBookingConditionPopup}
          bookingCondition={bidCheckoutDetails?.data?.cancellation_policy_data}
        />
      </div>
      <Dialog
        className="custompops"
        open={loginPopupDialog}
        onClose={() => {
          setLoginPopupDialog(false);
        }}
        fullWidth={fullScreen}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {t("Bids.Bidding")}
          {/* {t("CareersPage.CallUsat")} */}
          <Box display="flex" alignItems="center" className="closepop">
            <Box flexGrow={1}></Box>
            <Box>
              <IconButton
                onClick={() => {
                  setLoginPopupDialog(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogContentText style={{ textAlign: "center" }}>
            {t("Bids.BeforeBidding")}
          </DialogContentText>
          <button
            className="gen-btn login-pop-button"
            style={{ marginTop: "1em" }}
            onClick={() => {
              navigate("/login");
            }}
          >
            {t("Bids.Login")}
          </button>
        </DialogContent>
      </Dialog>

      {/* //////////Checkout main content end/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  bidCheckoutDetails: selectBidCheckoutDetails,
  hourlyCheckoutDetails: selectHourlyCheckoutDetails,
  hotelBooking: selectHotelBooking,
  languageToShow: selectlanguageToShow,
  userAuthData: selectUserLoginData,
  applyHotelOffers: selectApplyHotelOffers,
  saveGuestUserCheckoutData: selectSaveGuestUserCheckoutData,
  currencyToShow: selectcurrencyToShow,
  searchsavedData: selectFavouriteHotelSearchData,
  applyHotelOffersFailedData: selectApplyHotelOffersFailedData,
  validateUserEmail: selectValidateUserEmailData,
  hotelBookingLoading: selectHotelBookingLoading,
  applyHotelOffersLoading: selectApplyHotelOffersLoading,
  signUpmessage: selectSignUpMessage,
  currentUser: selectCurrentUser,
});
const mapDispatchToProps = (dispatch) => ({
  getBidCheckoutDetailsRequest: (data) =>
    dispatch(getBidCheckoutDetailsRequest(data)),
  getHourlyCheckoutDetailsRequest: (data) =>
    dispatch(getHourlyCheckoutDetailsRequest(data)),
  hotelBookingRequest: (data) => dispatch(hotelBookingRequest(data)),
  applyHotelOffersRequest: (data) => dispatch(applyHotelOffersRequest(data)),
  stateClearAfterTask: () => dispatch(stateClearAfterTask()),
  saveGuestUserCheckoutDataRequest: (data) =>
    dispatch(saveGuestUserCheckoutDataRequest(data)),
  hotelWheatherApiRequest: (data) => dispatch(hotelWheatherApiRequest(data)),
  validateUserEmailRequest: (data) => dispatch(validateUserEmailRequest(data)),
  validateEmailStateClearAfterTask: () =>
    dispatch(validateEmailStateClearAfterTask()),
});
export default connect(mapStateToProps, mapDispatchToProps)(BidGuestUser);
