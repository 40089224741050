import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";

const SmallMap = withScriptjs(
  withGoogleMap(({ onClick, center, children }) => (
    <GoogleMap
      defaultZoom={10}
      center={center}
      onClick={(e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        onClick({ lat, lng });
      }}
      options={{
        disableDefaultUI: true, // Disables default map UI
        fullscreenControl: false, // Disables fullscreen control
        streetViewControl: false, // Disables Street View control
        mapTypeControl: false, // Disables map type selector
        zoomControl: false, // Disables zoom control
        scaleControl: false, // Disables scale control
      }}
    >
      {children ?? null}
    </GoogleMap>
  ))
);

export default function SmallMapWidget({
  onClick,
  center = { lat: 0, lng: 0 },
  children,
}) {
  return (
    <SmallMap
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GoogleAPIKEY}`}
      loadingElement={<div style={{ height: "100%" }} />}
      containerElement={<div style={{ height: "100%" }} />}
      mapElement={<div style={{ height: "100%" }} />}
      onClick={onClick}
      center={center}
    >
      {children ?? null}
    </SmallMap>
  );
}
