import React from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getMyWalletPointsDataStart,
  getMyWalletListDataStart,
  buyWalletPointsRequest,
  getAllLoyaltyPointsRequest,
} from "../../redux/useraccount/useraccount.actions";
import {
  selectLoading,
  selectMyWalletPointsData,
  selectMyWalletListData,
  selectAllLoyaltyPointsData,
  selectBuyWalletPointsData,
} from "../../redux/useraccount/useraccount.selectors";
import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "../../redux/user/user.selectors";
import { selectlanguageToShow } from "../../redux/language/language.selectors";
import moment from "moment";
import PaginationWithDisplay from "../../components/paginationWithDisplay/paginationWithDisplay";
import { useTranslation } from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccount/MyAccountLeftMenu";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
const MypointsPage = ({
  selectLoading,
  myWalletListData,
  getMyWalletListDataStart,
  languageToShow,
  userAuthData,
  loyaltyPointsData,
  buyWalletPointsRequest,
  getAllLoyaltyPointsRequest,
  buyWalletPointsData,
}) => {
  const MySwal = withReactContent(Swal);
  const [t] = useTranslation("common");
  const [tabValue, setTabValue] = React.useState("all");
  const [page, setPage] = React.useState({
    page_no: 1,
    per_page_limit: 3,
    total: 0,
  });
  const [showBuyPointsPopup, setShowBuyPointsPopup] = React.useState(false);
  const [pointSelected, setPointSelected] = React.useState(false);
  const [loyaltyId, setLoyaltyId] = React.useState();
  const [loyaltyPointSelected, setLoyaltyPointSelected] = React.useState({});

  React.useEffect(() => {
    getAllLoyaltyPointsRequest(data);
  }, [languageToShow]);

  const popupRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowBuyPointsPopup(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleTabChange = (value) => {
    if (value == "all") {
      setTabValue(value);
    } else if (value == "spent ") {
      setTabValue(value);
    } else if (value == "received") {
      setTabValue(value);
    } else {
      setTabValue(value);
    }
  };

  const data = {
    languageToShow: languageToShow,
    token: userAuthData != null ? userAuthData.token : "",
  };

  React.useEffect(() => {
    var data = {};
    if (tabValue == "all") {
      data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        type: tabValue,
        skip: 1,
        limit: page.per_page_limit,
      };
      getMyWalletListDataStart(data);
    } else if (tabValue == "spent") {
      data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        type: tabValue,
        skip: 1,
        limit: page.per_page_limit,
      };
      getMyWalletListDataStart(data);
    } else if (tabValue == "received") {
      data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        type: tabValue,
        skip: 1,
        limit: page.per_page_limit,
      };
      getMyWalletListDataStart(data);
    } else {
      data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        type: tabValue,
        skip: 1,
        limit: page.per_page_limit,
      };
      getMyWalletListDataStart(data);
    }
  }, [tabValue]);

  React.useEffect(() => {
    if (myWalletListData != null) {
      setPage({
        ...page,
        total: myWalletListData.total_count,
      });
    }
  }, [myWalletListData]);

  const handleChangePage = (event, page, limit) => {
    // setPage(newPage);
    setPage({
      ...page,
      page_no: page,
      per_page_limit: limit,
    });

    let data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      type: tabValue,
      skip: page,
      limit: limit,
    };
    getMyWalletListDataStart(data);
  };

  const handlePopupBox = () => {
    setShowBuyPointsPopup(true);
  };

  const getPoints = (data, index) => {
    setLoyaltyId(index);
    setPointSelected(!pointSelected);
    setLoyaltyPointSelected(data);
  };

  const buyPointsHandler = () => {
    const postData = {
      loyalty_point_id: loyaltyPointSelected.id,
      device_type: "web",
    };
    const data = {
      postData,
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
    };

    if (userAuthData != null) {
      buyWalletPointsRequest(data);
      setShowBuyPointsPopup(false);
      setLoyaltyId();
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: t("MyAccount.MyPointsPage.Pleaseloginfirst"),
      });
    }
  };

  React.useEffect(() => {
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      type: tabValue,
      skip: 1,
      limit: page.per_page_limit,
    };
    getMyWalletListDataStart(data);
    if (buyWalletPointsData != null) {
      if (buyWalletPointsData.success == true) {
        window.open(buyWalletPointsData.data.url, "mozillaTab");
        getMyWalletListDataStart(data);
      } else {
      }
    }
  }, [buyWalletPointsData, languageToShow]);

  const recivedFetchData = () => {};
  return (
    <>
      {/* for SEO purpose */}
      <Helmet>
        <title>My Points - Track and Redeem Rewards | Wfrlee</title>
        <meta
          name="description"
          content="Manage and redeem your Wfrlee points effortlessly. Access your rewards balance, view transaction history, and explore ways to earn more points. Log in now to see your benefits!"
        />
      </Helmet>

      {/* //////////Mypoints page Section/////////// */}
      <div className="myaccountpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="accounttotalsection">
                <div className="leftmenu">
                  <div className="account-titlearea">
                    <h2>{t("MyAccount.heading")}</h2>
                    <p>{t("MyAccount.subHeading")}</p>
                  </div>
                  <MyAccountLeftMenu />
                </div>
                <div className="rightsection">
                  <h1> {t("MyAccount.MyPointsPage.heading")}</h1>
                  <div className="accountpoint">
                    <div className="pointsarea justify-content-between align-items-center">
                      <div className="d-flex align-items-center pointsarea mb-0">
                        <span>
                          {t("MyAccount.MyPointsPage.MywfrleePoints")}{" "}
                          <label>
                            {myWalletListData != null
                              ? myWalletListData?.data.total_points
                              : ""}
                          </label>
                        </span>
                        <Link to="/" className="gen-btn">
                          {t("MyAccount.MyPointsPage.UsePoints")}
                        </Link>
                      </div>
                      <div className="walletdropdown">
                        <Button
                          onClick={handlePopupBox}
                          className={"buyPointBtn buy-btn"}
                        >
                          {t("MyAccount.MyPointsPage.BuyPoints")}
                        </Button>
                        <div
                          className={
                            showBuyPointsPopup == true
                              ? " popbox popupFadeIn"
                              : " popbox popupFadeOut"
                          }
                          ref={popupRef}
                        >
                          <h3>
                            <Button
                              className="popclose onClickBtn p-0"
                              onClick={() => {
                                setShowBuyPointsPopup(false);
                                setLoyaltyId();
                              }}
                            >
                              <img src="./img/popclose.svg" alt="" />
                            </Button>
                            {t("MyAccount.MyPointsPage.BuyPoints")}{" "}
                            <span>
                              {t(
                                "MyAccount.MyPointsPage.GetPointsinYourwallet"
                              )}
                            </span>
                          </h3>
                          <div className="pointboxarea">
                            {loyaltyPointsData != null
                              ? loyaltyPointsData?.data.map((data, index) => (
                                  <div
                                    key={index}
                                    className={
                                      loyaltyId === index
                                        ? "selectedPointsmallbox pointsmallbox"
                                        : " pointsmallbox"
                                    }
                                    onClick={() => getPoints(data, index)}
                                    // style={{
                                    //   background: loyaltyId === index ? 'lightblue' : 'white'
                                    // }}
                                  >
                                    {data.type} <span>{data.point_value}</span>{" "}
                                    {t("MyAccount.MyPointsPage.Points")}
                                    <Link to="#">
                                      {data.currency} {data.price}
                                    </Link>
                                  </div>
                                ))
                              : t("MyAccount.MyPointsPage.noPointAvailable")}
                          </div>

                          <Button
                            className="gen-btn submitBuyPointsBtn"
                            onClick={buyPointsHandler}
                          >
                            {t("MyAccount.MyPointsPage.BuyPoints")}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Tabs
                      defaultActiveKey={tabValue}
                      id="booking-tab"
                      className="bookingtab"
                      onSelect={handleTabChange}
                    >
                      <Tab
                        eventKey="all"
                        title={t("MyAccount.MyPointsPage.All")}
                      >
                        {selectLoading ? (
                          <Skeleton count={10} />
                        ) : (
                          myWalletListData != null &&
                          (
                            myWalletListData?.data.wallet_transactions || []
                          ).map((transaction, index) => (
                            <div
                              className="bookingbox"
                              key={index}
                              style={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div className="pointslft">
                                <p className="pointdate">
                                  {" "}
                                  {moment(new Date(transaction.date)).format(
                                    "ll"
                                  )}
                                </p>
                                <p>
                                  <Link to="#">{transaction.remarks}</Link>
                                </p>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  width: "100%",
                                }}
                              >
                                <div style={{ width: "45%" }}>
                                  {" "}
                                  <span>
                                    <label style={{ color: "rgb(223 73 73)" }}>
                                      {t("MyAccount.MyPointsPage.expire_date")}{" "}
                                      :
                                    </label>{" "}
                                    {transaction.expire_date !== null
                                      ? moment(
                                          new Date(transaction.expire_date)
                                        ).format("ll")
                                      : t(
                                          "MyAccount.MyPointsPage.no_expire_date"
                                        )}
                                  </span>
                                </div>
                                <div className="pointsright">
                                  {transaction.points}{" "}
                                  {t("MyAccount.MyPointsPage.Points")}
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </Tab>
                      <Tab
                        eventKey={"received"}
                        title={t("MyAccount.MyPointsPage.Received")}
                      >
                        {selectLoading ? (
                          <Skeleton count={10} />
                        ) : (
                          myWalletListData != null &&
                          (myWalletListData?.data.wallet_transactions).map(
                            (transaction, index) => (
                              <div
                                className="bookingbox"
                                key={index}
                                style={{
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div className="pointslft">
                                  <p className="pointdate">
                                    {" "}
                                    {moment(new Date(transaction.date)).format(
                                      "ll"
                                    )}
                                  </p>
                                  <p>
                                    <Link to="#">{transaction.remarks}</Link>
                                  </p>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                  }}
                                >
                                  <div style={{ width: "45%" }}>
                                    {" "}
                                    <span>
                                      <label
                                        style={{ color: "rgb(223 73 73)" }}
                                      >
                                        {t(
                                          "MyAccount.MyPointsPage.expire_date"
                                        )}{" "}
                                        :
                                      </label>{" "}
                                      {transaction.expire_date !== null
                                        ? moment(
                                            new Date(transaction.expire_date)
                                          ).format("ll")
                                        : t(
                                            "MyAccount.MyPointsPage.no_expire_date"
                                          )}
                                    </span>
                                  </div>
                                  <div className="pointsright">
                                    {transaction.points}{" "}
                                    {t("MyAccount.MyPointsPage.Points")}
                                  </div>
                                </div>
                              </div>
                              // </InfiniteScroll>
                            )
                          )
                        )}
                      </Tab>
                      <Tab
                        eventKey="spent"
                        title={t("MyAccount.MyPointsPage.Spent")}
                      >
                        {selectLoading ? (
                          <Skeleton count={10} />
                        ) : (
                          myWalletListData != null &&
                          (myWalletListData?.data.wallet_transactions).map(
                            (transaction, index) => (
                              <div
                                className="bookingbox"
                                key={index}
                                style={{
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <div className="pointslft">
                                  <p className="pointdate">
                                    {" "}
                                    {moment(new Date(transaction.date)).format(
                                      "ll"
                                    )}
                                  </p>
                                  <p>
                                    <Link to="#">{transaction.remarks}</Link>
                                  </p>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                  }}
                                >
                                  <div style={{ width: "45%" }}>
                                    {" "}
                                    <span>
                                      <label
                                        style={{ color: "rgb(223 73 73)" }}
                                      >
                                        {t(
                                          "MyAccount.MyPointsPage.expire_date"
                                        )}{" "}
                                        :
                                      </label>{" "}
                                      {transaction.expire_date !== null
                                        ? moment(
                                            new Date(transaction.expire_date)
                                          ).format("ll")
                                        : t(
                                            "MyAccount.MyPointsPage.no_expire_date"
                                          )}
                                    </span>
                                  </div>
                                  <div className="pointsright">
                                    {transaction.points}{" "}
                                    {t("MyAccount.MyPointsPage.Points")}
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        )}
                      </Tab>
                    </Tabs>

                    <PaginationWithDisplay
                      page={page}
                      handleChangePage={handleChangePage}
                      // displayShow={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Mypoints page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectLoading: selectLoading,
  myWalletPointsData: selectMyWalletPointsData,
  myWalletListData: selectMyWalletListData,
  userAuthData: selectUserLoginData,
  userSocialAuthData: selectSocialLoginUser,
  languageToShow: selectlanguageToShow,
  loyaltyPointsData: selectAllLoyaltyPointsData,
  buyWalletPointsData: selectBuyWalletPointsData,
});
const mapDispatchToProps = (dispatch) => ({
  getMyWalletPointsDataStart: (data) =>
    dispatch(getMyWalletPointsDataStart(data)),
  getMyWalletListDataStart: (data) => dispatch(getMyWalletListDataStart(data)),
  buyWalletPointsRequest: (data) => dispatch(buyWalletPointsRequest(data)),
  getAllLoyaltyPointsRequest: (data) =>
    dispatch(getAllLoyaltyPointsRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MypointsPage);
