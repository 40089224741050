import UserActionTypes from "./user.types";

export function requestSocialLogin(request) {
  return {
    type: UserActionTypes.SOCIAL_LOGIN_REQUEST,
    payload: request,
  };
}
export function onSocialLoginSuccess(response) {
  return {
    type: UserActionTypes.SOCIAL_LOGIN_SUCCESS,
    payload: response,
  };
}

export function socialLoginFailed(response) {
  return {
    type: UserActionTypes.SOCIAL_LOGIN_FAILED,
    payload: response,
  };
}

export const googleSignInStart = (request) => ({
  type: UserActionTypes.GOOGLE_SIGN_IN_START,
  payload: request,
});

export const SignInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const SignInFailure = (data) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: data,
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword,
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const signUpStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_UP_START,
  payload: userCredentials,
});

export const signUpSuccess = (data) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: data,
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FAILURE,
  payload: error,
});

export const emailVerificationStart = (data) => ({
  type: UserActionTypes.EMAIL_VERIFICATION_START,
  payload: data,
});

export const emailVerificationSuccess = (data) => ({
  type: UserActionTypes.EMAIL_VERIFICATION_SUCCESS,
  payload: data,
});

export const emailVerificationFailure = (error) => ({
  type: UserActionTypes.EMAIL_VERIFICATION_FAILURE,
  payload: error,
});

export const UserLogoutStart = (data) => ({
  type: UserActionTypes.USER_LOGOUT_START,
});
export const UserLogoutSuccess = (data) => ({
  type: UserActionTypes.USER_LOGOUT_SUCCESS,
  payload: data,
});

export const UserLogoutFailure = (err) => ({
  type: UserActionTypes.USER_LOGOUT_FAILURE,
  payload: err,
});

export const ForgotPasswordStart = (data) => ({
  type: UserActionTypes.FORGOT_PASSWORD_START,
  payload: data,
});

export const ForgotPasswordSuccess = (data) => ({
  type: UserActionTypes.FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const ForgotPasswordFailure = (err) => ({
  type: UserActionTypes.FORGOT_PASSWORD_FAILURE,
  payload: err,
});

export const ForgotPasswordReset = (data) => ({
  type: UserActionTypes.FORGOT_PASSWORD_RESET,
  payload: data,
});

export const ResetPasswordStart = (resetPasswordData) => ({
  type: UserActionTypes.RESET_PASSWORD_START,
  payload: resetPasswordData,
});

export const ResetPasswordSuccess = (resetPasswordData) => ({
  type: UserActionTypes.RESET_PASSWORD_SUCCESS,
  payload: resetPasswordData,
});

export const ResetPasswordFailure = (err) => ({
  type: UserActionTypes.RESET_PASSWORD_FAILURE,
  payload: err,
});

export const ResetPasswordAfterForgotStart = (data) => ({
  type: UserActionTypes.RESET_PASSWORD_AFTER_FORGOT_START,
  payload: data,
});

export const ResetPasswordAfterForgotSuccess = (data) => ({
  type: UserActionTypes.RESET_PASSWORD_AFTER_FORGOT_SUCCESS,
  payload: data,
});

export const ResetPasswordAfterForgotFailure = (err) => ({
  type: UserActionTypes.RESET_PASSWORD_AFTER_FORGOT_FAILURE,
  payload: err,
});

export const ProfileDataUpdateRequest = (data) => ({
  type: UserActionTypes.USER_PROFILE_UPDATE_REQUEST,
  payload: data,
});

export const ProfileDataUpdateSuccess = (data) => ({
  type: UserActionTypes.USER_PROFILE_UPDATE_SUCCESS,
  payload: data,
});

export const ProfileDataUpdateFailure = (data) => ({
  type: UserActionTypes.USER_PROFILE_UPDATE_FAILURE,
  payload: data,
});
export const ProfileImageUpdateRequest = (data) => ({
  type: UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_REQUEST,
  payload: data,
});

export const ProfileImageUpdateSuccess = (data) => ({
  type: UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_SUCCESS,
  payload: data,
});

export const ProfileImageUpdateFailure = (data) => ({
  type: UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_FAILURE,
  payload: data,
});

export const ProfileImageUpdateMessageClear = (data) => ({
  type: UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_MESSAGE_CLEAR,
  payload: data,
});

export const UserProfileDetailsRequest = (data) => ({
  type: UserActionTypes.USER_PROFILE_DATA_REQUEST,
  payload: data,
});

export const UserProfileDetailsUpdateThroughProfile = (data) => ({
  type: UserActionTypes.USER_PROFILE_DATA_UPDATE_THROUGH_PROFILE,
  payload: data,
});

export const UserProfileDetailsSuccess = (data) => ({
  type: UserActionTypes.USER_PROFILE_DATA_SUCCESS,
  payload: data,
});

export const UserProfileDetailsFailure = (data) => ({
  type: UserActionTypes.USER_PROFILE_DATA_FAILURE,
  payload: data,
});

export const stateClearAfterTask = () => ({
  type: UserActionTypes.STATE_CLEAR_AFTER_TASK,
});

/***** Save checkout guest User Form Data   ***/
export const saveGuestUserCheckoutDataRequest = (
  saveGuestUserCheckoutData
) => ({
  type: UserActionTypes.SAVE_GUEST_USER_CHECKOUT_DATA,
  payload: saveGuestUserCheckoutData,
});

/**** VALIDATE USER EMAIL **** */
/************ HOTEL RE-SEND CONFIRMATION    *************** */
export const validateUserEmailRequest = (validateUserEmail) => ({
  type: UserActionTypes.VALIDATE_USER_EMAIL_REQUEST,
  payload: validateUserEmail,
});

export const validateUserEmailSuccess = (validateUserEmail) => ({
  type: UserActionTypes.VALIDATE_USER_EMAIL_SUCCESS,
  payload: validateUserEmail,
});

export const validateUserEmailFailure = (error) => ({
  type: UserActionTypes.VALIDATE_USER_EMAIL_FAILED,
  payload: error,
});

export const validateEmailStateClearAfterTask = (data) => ({
  type: UserActionTypes.VALIDATE_EMAIL_STATE_CLEAR_AFTER_TASK,
  payload: data,
});

export const updateUserData = (data) => ({
  type: UserActionTypes.UPDATE_USER_DATA,
  payload: data,
});
