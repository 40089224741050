import React, { useEffect, useState } from "react";
import { Dropdown, Tabs, Tab, Button } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  createSearchParams,
  useSearchParams
} from "react-router-dom";
import { GrFacebookOption } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrLinkedinOption } from "react-icons/gr";
import { GrInstagram } from "react-icons/gr";
import { GrYoutube } from "react-icons/gr";
import {
  hotelBookingHistoryRequest,
  hotelResendConfirmationRequest,
  hotelBookingListAddReview,
} from "./../../redux/hotels/hotel.actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { BsChevronDoubleLeft } from "react-icons/bs";
import {
  selectHotelBookingHistoryLoading,
  selectHotelBookingHistoryData,
  selectResendConfirmationLoading,
} from "./../../redux/hotels/hotel.selectors";
import {
  selectUserLoginData,
  selectSocialLoginUser,
} from "../../redux/user/user.selectors";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import TablePagination from "@mui/material/TablePagination";
import "react-loading-skeleton/dist/skeleton.css";
import ShareModal from "../../utils/ShareModal";
import { cancelBookingRequest } from "../../redux/useraccount/useraccount.actions";
import {
  selectCancelBookingData,
  selectCancelBookingLoader,
} from "../../redux/useraccount/useraccount.selectors";
import PaginationWithDisplay from "../../components/paginationWithDisplay/paginationWithDisplay";
import BookingConfirmationDetailsModal from "../../components/checkout/BookingConfirmationDetailsModal";
import { selectHotelAddReviewMessae } from "./../../redux/hotels/hotel.selectors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { showLoader, hideLoader } from "../../Loader/loader";
import { useTranslation } from "react-i18next";
import MyAccountLeftMenu from "../../components/MyAccount/MyAccountLeftMenu";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";
const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#384a78",
  },
  "& .MuiRating-iconHover": {
    color: "#384a78",
  },
});
const MybookingPage = ({
  hotelBookingHistoryRequest,
  selectHotelBookingHistoryData,
  languageToShow,
  selectHotelBookingHistoryLoading,
  userAuthData,
  hotelResendConfirmationRequest,
  cancelBookingRequest,
  selectCancelBookingData,
  hotelBookingListAddReview,
  resendConfirmationLoading,
  cancelBookingLoader,
  HotelAddReviewMessae
}) => {
  const [t] = useTranslation("common");
  const [tabValue, setTabValue] = React.useState("upcoming");

  const [openSharePopup, setOpenSharePopup] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [shareCode, setShareCode] = React.useState();
  const [shareContent, setShareContent] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [page, setPage] = React.useState({
    page_no: 1,
    per_page_limit: 3,
    total: 0,
  });
  const [showBookingConfirmModal, setShowBookingConfirmModal] = React.useState(
    false
  );
  const [bookingData, setBookingData] = React.useState();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [valueForMoney, setValueForMoney] = useState(0);
  const [staff, setStaff] = useState(0);
  const [facilities, setFacilities] = useState(0);
  const [cleanliness, setCleanliness] = useState(0);
  const [comfort, setComfort] = useState(0);
  const [hotelLocation, setHotelLocation] = useState(0);
  const [wifi, setWifi] = useState(0);
  const [overAll, setOverAll] = useState(0);
  const [textReview, setTextReview] = useState("");
  const [reviewBookingId, setReviewBookingId] = useState("");
  const [validationMsg, setValidationMsg] = useState("");
  const [bookinNumber, setBiikingNumber] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const textreviewhandleChange = (e) => {
    setValidationMsg("");
    setTextReview(e.target.value);
  };

  const handleReviewSubmit = (e) => {
    e.preventDefault();

    const data = {
      booking_number: reviewBookingId,
      value_for_money: valueForMoney,
      staff: staff,
      facilities: facilities,
      cleanliness: cleanliness,
      comfort: comfort,
      location: hotelLocation,
      wifi: wifi,
      overall: overAll,
      review: textReview,
    };
    hotelBookingListAddReview(data);
    handleClose();

  };
  const handleTabChange = (value) => {
    if (value == "upcoming") {
      setPage({
        ...page,
        page_no: 1,
      });
      setTabValue(value);
    } else if (value == "past") {
      setPage({
        ...page,
        page_no: 1,
      });
      setTabValue(value);
    } else if (value == "cancelled") {
      setPage({
        ...page,
        page_no: 1,
      });
      setTabValue(value);
    } else {
      setPage({
        ...page,
        page_no: 1,
      });
      setTabValue(value);
    }
  };

  React.useEffect(() => {
    var data = {};
    if (tabValue == "upcoming") {
      data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        filterbystatus: tabValue,
        skip: 1,
        limit: page.per_page_limit,
      };
      hotelBookingHistoryRequest(data);

    } else if (tabValue == "past") {
      data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        filterbystatus: tabValue,
        skip: 1,
        limit: page.per_page_limit,
      };
      hotelBookingHistoryRequest(data);

    } else if (tabValue == "cancelled") {
      data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        filterbystatus: tabValue,
        skip: 1,
        limit: page.per_page_limit,
      };
      hotelBookingHistoryRequest(data);

    } else {
      data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        filterbystatus: tabValue,
        skip: 1,
        limit: page.per_page_limit,
      };

      hotelBookingHistoryRequest(data);

    }
  }, [tabValue]);

  useEffect(() => {
    if (selectHotelBookingHistoryData != null) {
      setPage({
        ...page,
        total: selectHotelBookingHistoryData.total_count,
      });
    }
  }, [selectHotelBookingHistoryData]);

  const handleChangePage = (event, page, limit) => {
    setPage({
      ...page,
      page_no: page,
      per_page_limit: limit,
    });
    let data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      filterbystatus: tabValue,
      skip: page,
      limit: limit,
    };
    hotelBookingHistoryRequest(data);
  };



  const handleResendConfirmation = (bookingNo) => {
    const postData = {
      booking_number: bookingNo,
    };
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      postData: postData,
    };
    hotelResendConfirmationRequest(data);
  };

  const handleSharePopup = (shareData, shareContentData) => {
    if (shareData) {

      setShareCode(shareData);
      setShareContent(shareContentData);
      setOpenSharePopup(!openSharePopup);
    } else {
    }
  };

  const onCopy = React.useCallback((value) => {
    setIsCopied(value);
    setOpenSharePopup(false);
  }, []);

  const handleCancelBooking = () => {
    const postData = {
      booking_number: bookinNumber,
      status: "canceled",
    };
    const data = {
      languageToShow: languageToShow,
      postData: postData,
    };
    cancelBookingRequest(data);
    setDialogOpen(false);

  };

  React.useEffect(() => {
    const data = {
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
      filterbystatus: tabValue,
    };
    if (selectCancelBookingData != null) {
      if (selectCancelBookingData.success == true) {
        hotelBookingHistoryRequest(data);
      } else {
      }
    }
  }, [selectCancelBookingData]);

  const handleShowBookingDetailsPopup = (data) => {
    setShowBookingConfirmModal(true);
    setBookingData(data);
  };

  const gotoHotelDetails = (slug, cityname) => {
    var checkinDate = new Date();
    checkinDate.setDate(checkinDate.getDate() + 1);
    var checkoutDate = new Date();
    checkoutDate.setDate(checkoutDate.getDate() + 2);
    if (location.pathname == `/hotel-details/${slug}`) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          search_type: "bid",
          city: cityname,
          check_in_date: moment(new Date(checkinDate)).format("DD/MM/YYYY"),
          check_out_date: moment(new Date(checkoutDate)).format("DD/MM/YYYY"),

          adults: "1",
          children: "1",
          rooms: "1",
        }).toString(),
      });
    } else {
      navigate(
        {
          pathname: `/hotel-details/${slug}`,
          search: createSearchParams({
            search_type: "bid",
            city: cityname,
            check_in_date: moment(new Date(checkinDate)).format("DD/MM/YYYY"),
            check_out_date: moment(new Date(checkoutDate)).format("DD/MM/YYYY"),

            adults: "1",
            children: "1",
            rooms: "1",
          }).toString(),
        },
        { replace: false }
      );
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  React.useEffect(() => {
    if (resendConfirmationLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [resendConfirmationLoading]);

  React.useEffect(() => {
    if (cancelBookingLoader) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [cancelBookingLoader]);
  function MouseOver(event) {
    event.target.style.background = "red";
  }
  function MouseOut(event) {
    event.target.style.background = "";
  }

  useEffect(() => {
    if (HotelAddReviewMessae != null && HotelAddReviewMessae.message ==
      "Review and rating are saved successfully.") {
      const data = {
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
        filterbystatus: tabValue,
        skip: 1,
        limit: 3,
      };
      hotelBookingHistoryRequest(data);
    }
    setPage({
      ...page,
      page_no: 1,
      per_page_limit: 3,
    });
  }, [HotelAddReviewMessae])

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  useEffect(() => {
    if (searchParams.get('type') == "past") {
      setTabValue("past");
    }
  }, [searchParams.get('type')])
  return (
    <>

      {/* for SEO purpose */}
      <Helmet>
        <title>My Booking - Manage Your Reservations | Wfrlee</title>
        <meta name="description" content="Easily view and manage your bookings on Wfrlee. Update, modify or cancel your reservations, and access detailed booking information all in one place for your convenience." />
      </Helmet>

      {/* //////////Myprofile page Section/////////// */}
      <div className="myaccountpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="accounttotalsection">
                <div className="leftmenu">
                  <div className="account-titlearea">
                    <h2>{t("MyAccount.heading")}</h2>
                    <p>{t("MyAccount.subHeading")}</p>
                  </div>
                  <MyAccountLeftMenu />
                </div>
                <div className="rightsection">
                  <h1>{t("MyAccount.MyBookingsPage.heading")}</h1>
                  <div className="accountbooking">
                    <Tabs
                      defaultActiveKey={tabValue}
                      id="booking-tab"
                      className="bookingtab"
                      onSelect={handleTabChange}
                      activeKey={tabValue}
                    >
                      <Tab
                        eventKey="upcoming"
                        title={t("MyAccount.MyBookingsPage.Upcoming")}
                      >
                        {selectHotelBookingHistoryLoading ? (
                          <Skeleton count={10} />
                        ) : selectHotelBookingHistoryData != null &&
                          selectHotelBookingHistoryData?.data != null ? (
                          //   selectHotelBookingHistoryData.data
                          (selectHotelBookingHistoryData?.data || []).map(
                            (data, index) => (
                              <div className="bookingbox" key={index}>
                                <div
                                  className="bookingboxlft"
                                  onClick={() => {
                                    handleShowBookingDetailsPopup(data);
                                  }}
                                >
                                  <h3
                                    onClick={() => {
                                      gotoHotelDetails(
                                        data.hotel_details.slug,
                                        data.hotel_details.address.city_village
                                      );
                                    }}
                                  >
                                    {data.hotel_details.name}
                                  </h3>
                                  <p>
                                    {" "}
                                    {
                                      data.hotel_details.address.city_village
                                    }{" "}
                                    <br />
                                    {
                                      data.hotel_details.address.address_line1
                                    }, {data.hotel_details.address.country},{" "}
                                    {data.hotel_details.address.district}{" "}
                                    {data.hotel_details.address.pincode}
                                  </p>
                                </div>
                                <div
                                  className="bookingboxmid"
                                  onClick={() => {
                                    handleShowBookingDetailsPopup(data);
                                  }}
                                >
                                  <p>
                                    {" "}
                                    <label>
                                      {t(
                                        "MyAccount.MyBookingsPage.BookingDate"
                                      )}
                                    </label>{" "}
                                    <span>
                                      {moment(new Date(data.booking_date)).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t("MyAccount.MyBookingsPage.Bookingid")}
                                    </label>{" "}
                                    <span>{data.booking_number}</span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t(
                                        "MyAccount.MyBookingsPage.Checkindate"
                                      )}
                                    </label>{" "}
                                    <span>
                                      {moment(new Date(data.checkin_date)).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </span>{" "}
                                    <span className="timebook">
                                      {data.checkin_time != null
                                        ? moment(new Date(`${data.checkin_date}`)).utc().format("hh:mm A")
                                        : ""}
                                    </span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t("MyAccount.MyBookingsPage.RoomType")}
                                    </label>{" "}
                                    <span>{data.room_type_details.name}</span>
                                  </p>
                                </div>
                                <div className="bookingboxrgt">
                                  <Button
                                    onClick={() =>
                                      handleResendConfirmation(
                                        data.booking_number
                                      )
                                    }
                                    className={"gen-btn"}
                                  >
                                    {t(
                                      "MyAccount.MyBookingsPage.ResendConfirmation"
                                    )}
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      handleSharePopup(data.share_link, `
Your booking is confirmed.
Hotel name: ${data.hotel_details.name}
Booking id: ${data.booking_number}
Check in date: ${moment(new Date(data.checkin_date)).format(
                                        "DD MMM, YYYY"
                                      )}

to view the complete booking , Click here.
${data.share_link}`);
                                    }}
                                    className={"gen-btn"}
                                  >
                                    {" "}
                                    {t(
                                      "MyAccount.MyBookingsPage.ShareConfirmation"
                                    )}
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setBiikingNumber(data.booking_number);
                                      setDialogOpen(true);

                                    }}
                                    className={"gen-btn"}
                                  >
                                    {" "}
                                    {t(
                                      "MyAccount.MyBookingsPage.CancelBooking"
                                    )}
                                  </Button>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <>
                            <div className="datanotfound">

                              <h2>
                                {t("MyAccount.MyBookingsPage.Sorry")}{" "}
                                <span>
                                  {selectHotelBookingHistoryData?.message}
                                </span>
                              </h2>
                            </div>
                          </>
                        )}
                      </Tab>
                      <Tab
                        eventKey="past"
                        title={t("MyAccount.MyBookingsPage.Past")}
                      >
                        {selectHotelBookingHistoryLoading ? (
                          <Skeleton count={10} />
                        ) : selectHotelBookingHistoryData != null &&
                          selectHotelBookingHistoryData?.data != null ? (

                          (selectHotelBookingHistoryData?.data || []).map(
                            (data, index) => (
                              <div className="bookingbox" key={index}>
                                <div
                                  className="bookingboxlft"
                                  onClick={() => {
                                    handleShowBookingDetailsPopup(data);
                                  }}
                                >
                                  <h3
                                    onClick={() => {
                                      gotoHotelDetails(
                                        data.hotel_details.slug,
                                        data.hotel_details.address.city_village
                                      );
                                    }}
                                  >
                                    {data.hotel_details.name}
                                  </h3>
                                  <p>
                                    {" "}
                                    {
                                      data.hotel_details.address.city_village
                                    }{" "}
                                    <br />
                                    {
                                      data.hotel_details.address.address_line1
                                    }, {data.hotel_details.address.country},{" "}
                                    {data.hotel_details.address.district}{" "}
                                    {data.hotel_details.address.pincode}
                                  </p>
                                </div>
                                <div
                                  className="bookingboxmid"
                                  onClick={() => {
                                    handleShowBookingDetailsPopup(data);
                                  }}
                                >
                                  <p>
                                    {" "}
                                    <label>
                                      {t(
                                        "MyAccount.MyBookingsPage.BookingDate"
                                      )}
                                    </label>{" "}
                                    <span>
                                      {moment(new Date(data.booking_date)).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t("MyAccount.MyBookingsPage.Bookingid")}
                                    </label>{" "}
                                    <span>{data.booking_number}</span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t(
                                        "MyAccount.MyBookingsPage.Checkindate"
                                      )}
                                    </label>{" "}
                                    <span>
                                      {moment(new Date(data.checkin_date)).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </span>{" "}
                                    <span className="timebook">{data.checkin_time != null
                                      ? moment(new Date(`${data.checkin_date}`), [
                                        "hh:mm",
                                      ]).format("hh:mm A")
                                      : ""}</span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t("MyAccount.MyBookingsPage.RoomType")}
                                    </label>{" "}
                                    <span>{data.room_type_details.name}</span>
                                  </p>
                                </div>
                                <div className="bookingboxrgt">
                                  <Button
                                    onClick={() =>
                                      handleResendConfirmation(
                                        data.booking_number
                                      )
                                    }
                                    className={"gen-btn"}
                                  >
                                    {t(
                                      "MyAccount.MyBookingsPage.ResendConfirmation"
                                    )}
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      handleSharePopup(data.share_link, `
Your booking is confirmed.
Hotel name: ${data.hotel_details.name}
Booking id: ${data.booking_number}
Check in date: ${moment(new Date(data.checkin_date)).format(
                                        "DD MMM, YYYY"
                                      )}

to view the complete booking , Click here.
${data.share_link}`);
                                    }}
                                    className={"gen-btn"}
                                  >
                                    {" "}
                                    {t(
                                      "MyAccount.MyBookingsPage.ShareConfirmation"
                                    )}
                                  </Button>

                                  {data.review_button_to_show ?
                                    <Button className={"gen-btn"} onClick={() => { handleOpen(); setReviewBookingId(data.booking_number) }}>{t("MyAccount.MyBookingsPage.RateAndReview")}</Button>
                                    : null}
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <>
                            <div className="datanotfound">

                              <h2>
                                {t("MyAccount.MyBookingsPage.Sorry")}{" "}
                                <span>
                                  {selectHotelBookingHistoryData?.message}
                                </span>
                              </h2>
                            </div>
                          </>
                        )}
                      </Tab>
                      <Tab
                        eventKey="cancelled"
                        title={t("MyAccount.MyBookingsPage.Cancelled")}
                      >
                        {selectHotelBookingHistoryLoading ? (
                          <Skeleton count={10} />
                        ) : selectHotelBookingHistoryData != null &&
                          selectHotelBookingHistoryData?.data != null ? (
                          (selectHotelBookingHistoryData?.data || []).map(
                            (data, index) => (
                              <div className="bookingbox" key={index}>
                                <div
                                  className="bookingboxlft"
                                  onClick={() => {
                                    handleShowBookingDetailsPopup(data);
                                  }}
                                >
                                  <h3
                                    onClick={() => {
                                      gotoHotelDetails(
                                        data.hotel_details.slug,
                                        data.hotel_details.address.city_village
                                      );
                                    }}
                                  >
                                    {data.hotel_details.name}
                                  </h3>
                                  <p>
                                    {" "}
                                    {
                                      data.hotel_details.address.city_village
                                    }{" "}
                                    <br />
                                    {
                                      data.hotel_details.address.address_line1
                                    }, {data.hotel_details.address.country},{" "}
                                    {data.hotel_details.address.district}{" "}
                                    {data.hotel_details.address.pincode}
                                  </p>
                                </div>
                                <div
                                  className="bookingboxmid"
                                  onClick={() => {
                                    handleShowBookingDetailsPopup(data);
                                  }}
                                >
                                  <p>
                                    {" "}
                                    <label>
                                      {t(
                                        "MyAccount.MyBookingsPage.BookingDate"
                                      )}
                                    </label>{" "}
                                    <span>
                                      {moment(new Date(data.booking_date)).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t("MyAccount.MyBookingsPage.Bookingid")}
                                    </label>{" "}
                                    <span>{data.booking_number}</span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t(
                                        "MyAccount.MyBookingsPage.Checkindate"
                                      )}
                                    </label>{" "}
                                    <span>
                                      {moment(new Date(data.checkin_date)).format(
                                        "DD MMM, YYYY"
                                      )}
                                    </span>{" "}
                                    <span className="timebook">{data.checkin_time != null
                                      ? moment(new Date(`${data.checkin_date}`), [
                                        "hh:mm",
                                      ]).format("hh:mm A")
                                      : ""}</span>
                                  </p>
                                  <p>
                                    {" "}
                                    <label>
                                      {t("MyAccount.MyBookingsPage.RoomType")}
                                    </label>{" "}
                                    <span>{data.room_type_details.name}</span>
                                  </p>
                                </div>
                                <div className="bookingboxrgt">

                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <>
                            <div className="datanotfound">

                              <h2>
                                {t("MyAccount.MyBookingsPage.Sorry")}{" "}
                                <span>
                                  {selectHotelBookingHistoryData?.message}
                                </span>
                              </h2>
                            </div>
                          </>
                        )}{" "}
                      </Tab>
                    </Tabs>
                    <ShareModal
                      setOpenSharePopup={setOpenSharePopup}
                      openSharePopup={openSharePopup}
                      shareCode={shareCode}
                      setShareCode={setShareCode}
                      shareContent={shareContent}
                      setShareContent={setShareContent}
                      onCopy={onCopy}
                      isCopied={isCopied}
                      setIsCopied={setIsCopied}
                    />
                    <PaginationWithDisplay
                      page={page}
                      handleChangePage={handleChangePage}

                    />

                    <BookingConfirmationDetailsModal
                      setShowDialog={setShowBookingConfirmModal}
                      isOpen={showBookingConfirmModal}
                      bookingData={
                        bookingData != undefined ? bookingData : null
                      }
                      cancelButtonLabel={t(
                        "MyAccount.MyBookingsPage.bookingDetailsPopup.Close"
                      )}
                      cancelDialogHandler={() => {
                        setShowBookingConfirmModal(false);

                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="customratepop">
            <Box
              sx={{
                "& > legend": { mt: 2 },
              }}
            >
              <div className="row">
                <div className="col-sm-4">
                  <h3>
                    {t("MyAccount.MyBookingsPage.ratingAndReviewPopup.RateUs")}
                  </h3>
                </div>
                <div className="col-sm-8">
                  <ul className="reviewlist">
                    <li>{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Great")}</li>
                    <li>{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Neutral")}</li>
                    <li>{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Poor")}</li>
                  </ul>
                </div>
              </div>

              <form onSubmit={handleReviewSubmit}>
                <ul>
                  <li><Typography component="legend">{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.ValueForMoney")}</Typography>
                    <StyledRating
                      name="customized-color"
                      defaultValue={0}
                      getLabelText={(value) =>

                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      precision={1}
                      icon={<Brightness1Icon fontSize="inherit" />}
                      emptyIcon={<Brightness1Icon fontSize="inherit" />}
                      max={10}
                      onChange={(event, newValue) => {
                        setValueForMoney(newValue);
                      }}
                    /></li>
                  <li><Typography component="legend">{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Staff")}</Typography>
                    <StyledRating
                      name="customized-color"
                      defaultValue={0}
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      precision={1}
                      icon={<Brightness1Icon fontSize="inherit" />}
                      emptyIcon={<Brightness1Icon fontSize="inherit" />}
                      max={10}
                      onChange={(event, newValue) => {
                        setStaff(newValue);
                      }}
                    /></li>
                  <li><Typography component="legend">{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Facilities")}</Typography>
                    <StyledRating
                      name="customized-color"
                      defaultValue={0}
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      precision={1}
                      icon={<Brightness1Icon fontSize="inherit" />}
                      emptyIcon={<Brightness1Icon fontSize="inherit" />}
                      max={10}
                      onChange={(event, newValue) => {
                        setFacilities(newValue);
                      }}
                    /></li>
                  <li><Typography component="legend">{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Cleanliness")}</Typography>
                    <StyledRating
                      name="customized-color"
                      defaultValue={0}
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      precision={1}
                      icon={<Brightness1Icon fontSize="inherit" />}
                      emptyIcon={<Brightness1Icon fontSize="inherit" />}
                      max={10}
                      onChange={(event, newValue) => {
                        setCleanliness(newValue);
                      }}
                    /></li>
                  <li><Typography component="legend">{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Comfort")}</Typography>
                    <StyledRating
                      name="customized-color"
                      defaultValue={0}
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      precision={1}
                      icon={<Brightness1Icon fontSize="inherit" />}
                      emptyIcon={<Brightness1Icon fontSize="inherit" />}
                      max={10}
                      onChange={(event, newValue) => {
                        setComfort(newValue);
                      }}
                    /></li>
                  <li><Typography component="legend">{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Location")}</Typography>
                    <StyledRating
                      name="customized-color"
                      defaultValue={0}
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      precision={1}
                      icon={<Brightness1Icon fontSize="inherit" />}
                      emptyIcon={<Brightness1Icon fontSize="inherit" />}
                      max={10}
                      onChange={(event, newValue) => {
                        setHotelLocation(newValue);
                      }}
                    /></li>
                  <li><Typography component="legend">{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Wifi")}</Typography>
                    <StyledRating
                      name="customized-color"
                      defaultValue={0}
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      precision={1}
                      icon={<Brightness1Icon fontSize="inherit" />}
                      emptyIcon={<Brightness1Icon fontSize="inherit" />}
                      max={10}
                      onChange={(event, newValue) => {
                        setWifi(newValue);
                      }}
                    /></li>
                  <li><Typography component="legend">{t("MyAccount.MyBookingsPage.ratingAndReviewPopup.OverAll")}</Typography>
                    <StyledRating
                      name="customized-color"
                      defaultValue={0}
                      getLabelText={(value) =>
                        `${value} Heart${value !== 1 ? "s" : ""}`
                      }
                      precision={1}
                      icon={<Brightness1Icon fontSize="inherit" />}
                      emptyIcon={<Brightness1Icon fontSize="inherit" />}
                      max={10}
                      onChange={(event, newValue) => {
                        setOverAll(newValue);
                      }}
                    /></li>
                </ul>
                <div className="ratepoptextarae">
                  {/* <Typography component="legend">
                    {t(
                      "MyAccount.MyBookingsPage.ratingAndReviewPopup.Writeyourreview"
                    )}
                  </Typography> */}
                  <div className="d-flex">
                    <p>Message 1</p>
                    <p>Message 2</p>
                    <p>Message 3</p>
                  </div>
                  <textarea
                    style={{ width: "100%" }}
                    onChange={textreviewhandleChange}
                    name="reviewtext"
                    placeholder={t(
                      "MyAccount.MyBookingsPage.ratingAndReviewPopup.Writeyourreview"
                    )}
                    rows="4"
                    cols="50"
                  />
                  <p style={{ color: "red" }}>{validationMsg}</p>
                </div>
                <button type="submit" value="Submt">
                  {t("MyAccount.MyBookingsPage.ratingAndReviewPopup.Submit")}
                </button>
              </form>
            </Box>
          </Box>
        </Modal>
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}

        fullWidth={fullScreen}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle >
          Cancel Booking
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure want to cancel ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={"gen-btn-conformation-modal"} style={{ width: "142px" }} onClick={handleDialogClose}>
            No
          </Button>
          <Button className={"gen-btn-conformation-modal"} style={{ width: "142px" }} onClick={handleCancelBooking}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* //////////Myprofile page Section/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  selectHotelBookingHistoryLoading: selectHotelBookingHistoryLoading,
  selectHotelBookingHistoryData: selectHotelBookingHistoryData,
  userAuthData: selectUserLoginData,
  selectCancelBookingData: selectCancelBookingData,
  resendConfirmationLoading: selectResendConfirmationLoading,
  cancelBookingLoader: selectCancelBookingLoader,
  HotelAddReviewMessae: selectHotelAddReviewMessae
});
const mapDispatchToProps = (dispatch) => ({
  hotelBookingHistoryRequest: (data) =>
    dispatch(hotelBookingHistoryRequest(data)),
  hotelResendConfirmationRequest: (data) =>
    dispatch(hotelResendConfirmationRequest(data)),
  cancelBookingRequest: (data) => dispatch(cancelBookingRequest(data)),
  hotelBookingListAddReview: (data) =>
    dispatch(hotelBookingListAddReview(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MybookingPage);
