import React, { useEffect, useState } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginForm from "./../../components/LoginForm/LoginForm";
import RegistrationForm from "./../../components/RegistrationForm/RegistrationForm";
import SocialLogin from "../../components/SocialLogin/SocialLogin";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectSocialLoginUser } from "./../../redux/user/user.selectors";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const LoginPage = ({ userData, handleCloseLoginPopup, search_type }) => {
  const [t] = useTranslation("common");
  const stateData = useSelector((state) => state.userReducer);
  const [currentTab, setCurrentTab] = useState("login");
  const currentLocation = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      currentLocation.pathname == "/login" ||
      currentLocation.pathname == "/bidguestuser" ||
      currentLocation.pathname == "/hourlyguestuser"
    ) {
      setCurrentTab("login");
    } else {
      setCurrentTab("sign-up");
    }
  }, [currentLocation.pathname]);
  function handleSelect(key) {
    setCurrentTab(key);
  }
  return (
    <>
      {/* Header Section sssss*/}

      {/* //////////loginSection/////////// */}

      {/* For SEO */}
      {currentLocation.pathname == "/login" && (
        <Helmet>
          <title>Secure Login: Access Your WFRlee Account Today</title>
          <meta
            name="description"
            content="Safely access your WFRlee account. Enjoy a secure login, manage resources, and connect with our vibrant community. Join us now!"
          />
        </Helmet>
      )}

      {currentLocation.pathname == "/sign-up" && (
        <Helmet>
          <title>Join WFRlee: Unlock a World of Resources and Community</title>
          <meta
            name="description"
            content="Unlock endless possibilities at WFRlee. Sign up now for a world of resources and community support. Your journey begins here!"
          />
        </Helmet>
      )}

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="logtotal customform">
              <Tabs
                activeKey={currentTab}
                id="login-tab"
                className="logintab"
                onSelect={handleSelect}
              >
                <Tab eventKey="login" title={t("loginAndRegisterPage.Login")}>
                  <LoginForm
                    handleCloseLoginPopup={handleCloseLoginPopup}
                    search_type={search_type}
                  />
                   <p className="text-center">
                    {t("loginAndRegisterPage.or")} <br />
                    {t("loginAndRegisterPage.Loginwith")}
                  </p>
                  <SocialLogin
                    handleCloseLoginPopup={handleCloseLoginPopup}
                    search_type={search_type}
                  />
                  <p className="text-center">
                    {t("loginAndRegisterPage.DontHaveanAccount")}{" "}
                    <span
                      // to="/sign-up"
                      onClick={() => {
                        if (
                          currentLocation.pathname == "/bidguestuser" ||
                          currentLocation.pathname == "/hourlyguestuser"
                        ) {
                          setCurrentTab("sign-up");
                        } else {
                          navigate("/sign-up");
                        }
                      }}
                      className="login_link"
                    >
                      {t("loginAndRegisterPage.Signup")}
                    </span>
                  </p>
                  <p className="text-center pt-30">
                    {t("loginAndRegisterPage.UnabletoLogin")}{" "}
                    <Link to="/contactus">
                      {t("loginAndRegisterPage.Contactus")}
                    </Link>
                  </p>
                </Tab>
                <Tab
                  eventKey="sign-up"
                  title={t("loginAndRegisterPage.Signup")}
                >
                  <RegistrationForm
                    handleCloseLoginPopup={handleCloseLoginPopup}
                    search_type={search_type}
                  />
                  <p className="text-center">
                    {t("loginAndRegisterPage.or")} <br />
                    {t("loginAndRegisterPage.Loginwith")}
                  </p>
                  <SocialLogin
                    handleCloseLoginPopup={handleCloseLoginPopup}
                    search_type={search_type}
                  />
                  <p className="text-center">
                    {t("loginAndRegisterPage.AlreadyHaveanAccount")}{" "}
                    <span
                      // to="/login"
                      onClick={() => {
                        if (
                          currentLocation.pathname == "/bidguestuser" ||
                          currentLocation.pathname == "/hourlyguestuser"
                        ) {
                          setCurrentTab("login");
                        } else {
                          navigate("/login");
                        }
                      }}
                      className="login_link"
                    >
                      {t("loginAndRegisterPage.Signin")}
                    </span>
                  </p>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Footer Section/////////// */}
      {/* <Footer /> */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  userData: selectSocialLoginUser,
});

export default connect(mapStateToProps)(LoginPage);
