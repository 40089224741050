import { takeLatest, put, all, call } from "redux-saga/effects";
import axios from "axios";
import UserActionTypes from "./user.types";
import { errorToast, successToast } from "../../utils/toastHelper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  SignInSuccess,
  SignInFailure,
  signOutFailure,
  signOutSuccess,
  signUpSuccess,
  signUpFailure,
  emailVerificationSuccess,
  emailVerificationFailure,
  requestSocialLogin,
  onSocialLoginSuccess,
  socialLoginFailed,
  UserLogoutSuccess,
  ForgotPasswordSuccess,
  ForgotPasswordFailure,
  ResetPasswordSuccess,
  ResetPasswordFailure,
  ResetPasswordAfterForgotSuccess,
  ResetPasswordAfterForgotFailure,
  ProfileDataUpdateSuccess,
  ProfileDataUpdateFailure,
  ProfileImageUpdateSuccess,
  ProfileImageUpdateFailure,
  UserProfileDetailsSuccess,
  UserProfileDetailsFailure,
  validateUserEmailSuccess,
  validateUserEmailFailure,
} from "./user.actions";
import { setUserLocal, setTokenLocal } from "../../config/common.api";
import store from "./../store";
import { t } from "i18next";

var backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
var MySwal = withReactContent(Swal);
/*** Social Login Async Handler */
export function* socilaLoginAsyncHandler({ payload: data }) {
  try {
    if (
      data.social_platform == "google" ||
      data.social_platform == "facebook"
    ) {
      // response = yield call(loginService.SocialLogin, action.payload);
      const response = yield axios.post(
        `${backendUrlEndPoint}/user/social-login/`,
        data
      );
      if (response.data.success === true) {
        const { data } = response.data;
        setTokenLocal(data.token);
        setUserLocal(data);
        yield put(onSocialLoginSuccess(data));
        // successToast("Logged In Successfully");
      } else {
        MySwal.fire({
          confirmButtonText: "OK",
          confirmButtonColor: "#296da9",
          text: response.data.message,
        });
        //  errorToast(response.data.message);
        yield put(socialLoginFailed(response.data));
      }
    } else if (data.social_platform == "apple") {
      const response = yield axios.post(
        `${backendUrlEndPoint}/user/apple-login/`,
        data
      );
      if (response.data.success === true) {
        const { data } = response.data;
        setTokenLocal(data.token);
        setUserLocal(data);
        yield put(onSocialLoginSuccess(data));
        // successToast("Logged In Successfully");
      } else {
        MySwal.fire({
          confirmButtonText: "OK",
          confirmButtonColor: "#296da9",
          text: response.data.message,
        });
        //   errorToast(response.data.message);
        yield put(socialLoginFailed(response.data));
      }
    }
  } catch (error) {
    // console.log(error.message);
  }
}

export function* signInAfterSignup({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData);
}

export function* signUpWithCredentials({ payload: data }) {
  try {
    const user = yield axios.post(
      `${backendUrlEndPoint}/user/register/`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
        },
      }
    );
    if (user.data.success) {
      const responseWithBreaks = user.data.message.replace(/\n/g, "<br>");
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        html: responseWithBreaks,
      });
      //  successToast(user.data.message);
      setTokenLocal(user.data.token);
      yield put(signUpSuccess(user.data));
    } else {
      MySwal.fire({
        confirmButtonText: t("common:loginAndRegisterPage.Login"),
        confirmButtonColor: "#296da9",
        customClass: "swal-wide",
        html: user.data.message.replace(/\n/g, "<br />"),
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/login";
        }
      });
      // errorToast(user.data.message);
      yield put(signUpFailure(user.data));
    }
  } catch (error) {
    // console.log(error.message);
  }
}

export function* signInWithGoogle() {
  // try {
  // const { user }  = yield auth.signInWithPopup(googleprovider);
  // yield getSnapshotFromUserAuth(user);
  // } catch(error) {
  // yield put(SignInFailure(error));
  // }
}

export function* isUserAuthenticated() {
  // try {
  // const userAuth = yield getCurrentUser();
  // if (!userAuth) return;
  // yield getSnapshotFromUserAuth(userAuth);
  // } catch(error) {
  // yield put(SignInFailure(error));
  // }
}

export function* getSnapshotFromUserAuth(userAuth) {
  //  try {
  //  const userRef =  yield  call(createUserProfileDocument , userAuth);
  //  const userSnapshot = yield userRef.get();
  //  yield put(SignInSuccess({ id:userSnapshot.id , ...userSnapshot.data() }));
  //  } catch(error) {
  //  yield put(SignInFailure(error));
  //  }
}

export function* emailAuthenticationProcess({ payload: data }) {
  try {
    const user = yield axios.put(
      `${backendUrlEndPoint}/user/email-verify/` + data.token + "",
      data,

      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
        },
      }
    );
    if (user.data.success) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      // successToast(user.data.message);
      yield put(emailVerificationSuccess(user.data));
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      //  errorToast(user.data.message);
      yield put(emailVerificationFailure(user.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}
export function* signoutStartAsyncHandler({ payload: data }) {
  try {
    yield put(UserLogoutSuccess());
  } catch (error) {
    // console.log("Network error");
  }
}

export function* signInWithEmail({ payload: data }) {
  try {
    const user = yield axios.post(`${backendUrlEndPoint}/user/login/`, data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        mobile: true,
        "Accept-Language": `${store.getState().language.language}`,
      },
    });

    if (user.data.success) {
      setTokenLocal(user.data.data.token); // set token in local
      // successToast("Logged In Successfully");
      yield put(SignInSuccess(user.data.data));
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      // errorToast("Invaild Email/Phone or Password");
      yield put(SignInFailure(user.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

export function* forgotStartAsyncHandler({ payload: data }) {
  try {
    const user = yield axios.post(
      `${backendUrlEndPoint}/user/reset-password`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
        },
      }
    );
    if (user.data.success) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      // successToast("Email sent successfully");
      yield put(ForgotPasswordSuccess(user.data.data));
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      // errorToast("Email id not found");
      yield put(ForgotPasswordFailure(user.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

export function* resetPasswordStartAsyncHandler({ payload: data }) {
  try {
    const user = yield axios.put(
      `${backendUrlEndPoint}/user/update-password`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
      }
    );
    if (user.data.success) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      // successToast("Reset Password Change Succesfully");
      yield put(ResetPasswordSuccess(user.data.data));
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      //  errorToast(user.data.message);
      yield put(ResetPasswordFailure(user.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

export function* resetforgotPasswordAsyncHandle({ payload: data }) {
  try {
    const user = yield axios.put(
      `${backendUrlEndPoint}/user/change-password/` + data.token + "",
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
        },
      }
    );

    if (user.data.success) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      //  successToast(user.data.message);
      yield put(ResetPasswordAfterForgotSuccess(user.data));
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      //  errorToast(user.data.message);
      yield put(ResetPasswordAfterForgotFailure(user.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

export function* updateUserProfileStartAsyncHandle({ payload: data }) {
  try {
    const user = yield axios.put(`${backendUrlEndPoint}/user/update`, data, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        mobile: true,
        "Accept-Language": `${store.getState().language.language}`,
        Authorization:
          store.getState().user.currentUser != null
            ? "Bearer " + store.getState().user.currentUser.token + ""
            : "",
      },
    });

    if (user.data.success) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      //   successToast(user.data.message);
      yield put(ProfileDataUpdateSuccess(user.data));
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      //  errorToast(user.data.message);
      yield put(ProfileDataUpdateFailure(user.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

export function* updateUserProfileImageStartAsyncHandle({ payload: data }) {
  try {
    const user = yield axios.post(
      `${backendUrlEndPoint}/common/upload-user-profile-image`,
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${store.getState().language.language}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
      }
    );
    if (user.data.success) {
      //  successToast(user.data.message);
      yield put(ProfileImageUpdateSuccess(user.data));
    } else {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: user.data.message,
      });
      //  errorToast(user.data.message);
      yield put(ProfileImageUpdateFailure(user.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

export function* updatedUserProfileDataGetstartAsyncHandle({ payload: data }) {
  try {
    const user = yield axios.get(`${backendUrlEndPoint}/user/get`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        mobile: true,
        "Accept-Language": `${store.getState().language.language}`,
        Authorization:
          store.getState().user.currentUser != null
            ? "Bearer " + store.getState().user.currentUser.token + ""
            : "",
        mobile: true,
        "Accept-Language": `${data.language}`,
      },
    });
    if (user.data.success) {
      // successToast(user.data.message);
      yield put(
        UserProfileDetailsSuccess({
          ...user.data.data,
          token: store.getState().user.currentUser.token,
        })
      );
    } else {
      //  errorToast(user.data.message);
      yield put(UserProfileDetailsFailure(user.data));
    }
  } catch (error) {
    // console.log("Network Error");
  }
}

/************ VALIDATE USER EMAIL  *************** */
export function* validateUserEmailAsync({ payload: data }) {
  try {
    const response = yield axios.post(
      "" + backendUrlEndPoint + "/user/validate-email/",
      data,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          mobile: true,
          "Accept-Language": `${data.languageToShow}`,
          Authorization:
            store.getState().user.currentUser != null
              ? "Bearer " + store.getState().user.currentUser.token + ""
              : "",
        },
      }
    );

    if (response.data.success === true) {
      yield put(validateUserEmailSuccess(response.data));
      // successToast(response.data.message);
    } else {
      yield put(validateUserEmailFailure(response.data));
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: response.data.message,
      });
      // errorToast(response.data.message);
    }
  } catch (error) {
    // console.log("Network Error", error);
  }
}

export function* signOut() {
  // try {
  // yield auth.signOut();
  // yield put(signOutSuccess());
  // } catch(error) {
  // yield put(signOutFailure(error));
  // }
}

export function* onsignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUpWithCredentials);
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignup);
}

export function* onEmailVerificationStart() {
  yield takeLatest(
    UserActionTypes.EMAIL_VERIFICATION_START,
    emailAuthenticationProcess
  );
}

export function* onSocialLoginStart() {
  yield takeLatest(
    UserActionTypes.SOCIAL_LOGIN_REQUEST,
    socilaLoginAsyncHandler
  );
}
export function* onLogoutstart() {
  yield takeLatest(UserActionTypes.USER_LOGOUT_START, signoutStartAsyncHandler);
}

export function* onForgotPasswordstart() {
  yield takeLatest(
    UserActionTypes.FORGOT_PASSWORD_START,
    forgotStartAsyncHandler
  );
}

export function* onResetPasswordStart() {
  yield takeLatest(
    UserActionTypes.RESET_PASSWORD_START,
    resetPasswordStartAsyncHandler
  );
}

export function* onResetPasswordAfterForgotStart() {
  yield takeLatest(
    UserActionTypes.RESET_PASSWORD_AFTER_FORGOT_START,
    resetforgotPasswordAsyncHandle
  );
}

export function* onUpdateUserProfileStart() {
  yield takeLatest(
    UserActionTypes.USER_PROFILE_UPDATE_REQUEST,
    updateUserProfileStartAsyncHandle
  );
}

export function* onUpdateUserProfileImageStart() {
  yield takeLatest(
    UserActionTypes.USER_PROFILE_IMAGE_UPLOAD_REQUEST,
    updateUserProfileImageStartAsyncHandle
  );
}

export function* onUpdateUserProfileDataGetStart() {
  yield takeLatest(
    UserActionTypes.USER_PROFILE_DATA_REQUEST,
    updatedUserProfileDataGetstartAsyncHandle
  );
}

export function* onValidateUserEmailStart() {
  yield takeLatest(
    UserActionTypes.VALIDATE_USER_EMAIL_REQUEST,
    validateUserEmailAsync
  );
}

export function* userSagas() {
  yield all([
    call(onSocialLoginStart),
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onsignUpStart),
    call(onEmailVerificationStart),
    call(onLogoutstart),
    call(onForgotPasswordstart),
    call(onResetPasswordStart),
    call(onResetPasswordAfterForgotStart),
    call(onUpdateUserProfileStart),
    call(onUpdateUserProfileImageStart),
    call(onUpdateUserProfileDataGetStart),
    call(onValidateUserEmailStart),
  ]);
}
